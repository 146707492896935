import * as React from 'react';
import { Subscription } from 'rxjs';
import { IGameRow } from '../../interfaces/gameObjects';
import LineIcon from '../../style-guide/LineIcon';
import GameService, {
  defaultCategories,
  ICategoryChosen
} from '../../engine/services/GameService';
import Flex, { BasedLayer } from '../../style-guide/Flex';
import { noAnimation } from '../../style-guide/animation/pulse';
import PulseComponent from '../../style-guide/animation/PulseComponent';
import Layer from '../../engine/Layer';

interface IProps {
  rows: IGameRow[];
}

interface IState {
  animateIndex: number;
  categories: ICategoryChosen;
}

export default class ObjectIcons extends PulseComponent<IProps, IState> {
  public static defaultProps = {
    showAnimation: true
  };

  public state = {
    animateIndex: 0,
    animating: false,
    animation: noAnimation,
    categories: defaultCategories
  };

  private game = new GameService();
  private categorySub: Subscription | null = null;

  public componentDidMount() {
    this.categorySub = this.game.categories$.subscribe(
      this.updateCategories.bind(this)
    );
  }

  public componentWillUnmount() {
    if (this.categorySub) {
      this.categorySub.unsubscribe();
    }
  }

  public render() {
    const { rows } = this.props;
    const { animateIndex, animating, animation } = this.state;
    return (
      <Layer>
        <BasedLayer>
          <Flex container justify="space-around">
            {rows.map((row, i) => {
              const title =
                row.type === 'Studiespes' ? 'Studie-spes' : row.type;
              return (
                <LineIcon
                  key={i}
                  borderColor={row.color}
                  animating={animating}
                  animation={animateIndex === i ? animation : noAnimation}
                >
                  <Flex item>{title}</Flex>
                </LineIcon>
              );
            })}
          </Flex>
        </BasedLayer>
      </Layer>
    );
  }

  private updateCategories(categories: ICategoryChosen) {
    const index = Object.keys(this.state.categories).findIndex(x => {
      return categories[x] > this.state.categories[x];
    });

    this.startAnimation({ categories: { ...categories }, animateIndex: index });
  }
}
