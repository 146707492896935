import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { audioBufferToSource, loadAudioFile } from '../../utils/files';

type IThemeMusicSpeed = 1 | 2 | 3 | number;

let instance: SoundService;

export default class SoundService {
  public sound$!: BehaviorSubject<boolean>;

  private _windowFocusSub!: Subscription;
  private _windowBlurSub!: Subscription;
  private _filesLoaded: boolean = false;
  private _home!: HTMLAudioElement;
  private _theme1!: HTMLAudioElement;
  private _theme2!: HTMLAudioElement;
  private _theme3!: HTMLAudioElement;
  private _dilemma!: HTMLAudioElement;

  private _onClick!: ArrayBufferLike;
  private _gameStart!: ArrayBufferLike;
  private _gameOver!: ArrayBufferLike;

  private currentMusic!: HTMLAudioElement;

  constructor() {
    if (instance) {
      return instance;
    }

    instance = this;

    instance.sound$ = new BehaviorSubject<boolean>(false);
    instance._windowFocusSub = fromEvent(window, 'focus').subscribe(
      this.startMusic.bind(instance)
    );
    instance._windowBlurSub = fromEvent(window, 'blur').subscribe(
      this.stopMusic.bind(instance)
    );

    return instance;
  }

  public onDestroy() {
    this.off();
    this._windowFocusSub.unsubscribe();
    this._windowBlurSub.unsubscribe();
  }

  public async loadAudioFiles() {
    if (this._filesLoaded) {
      return;
    }
    this._filesLoaded = true;
    this._home = new Audio('/sound/home.mp3');
    this._theme1 = new Audio('/sound/theme1.mp3');
    this._theme2 = new Audio('/sound/theme2.mp3');
    this._theme3 = new Audio('/sound/theme3.mp3');
    this._dilemma = new Audio('/sound/dilemma.mp3');
    this._home.load();
    this._theme1.load();
    this._theme2.load();
    this._theme3.load();
    this._dilemma.load();

    this._onClick = await loadAudioFile('/sound/onClick.mp3');
    this._gameStart = await loadAudioFile('/sound/gameStart.mp3');
    this._gameOver = await loadAudioFile('/sound/gameOver.mp3');
    await this.setMenuMusic(true);
  }

  public async on() {
    this.sound$.next(true);
    await this.startMusic();
  }

  public async off() {
    this.sound$.next(false);
    await this.stopMusic();
  }

  public async setMenuMusic(play = true) {
    await this.toggleMusic(this._home, play);
  }

  public async setGameMusic(theme: IThemeMusicSpeed, play = true) {
    await this.toggleMusic(this.getThemeMusic(theme), play);
  }

  public async setDilemmaMusic(play = true) {
    await this.toggleMusic(this._dilemma, play);
  }

  public async gameStart() {
    if (this.muted()) return;
    await this.playSound(this._gameStart);
  }

  public async gameOver() {
    if (this.muted()) return;
    await this.playSound(this._gameOver);
  }

  public async onClick() {
    if (this.muted()) return;
    await this.playSound(this._onClick);
  }

  private getThemeMusic(theme: IThemeMusicSpeed) {
    switch (theme) {
      case 1:
        return this._theme1;
      case 2:
        return this._theme2;
      default:
        return this._theme3;
    }
  }

  private async toggleMusic(music: HTMLAudioElement, play: boolean) {
    if (play) {
      await this.setMusic(music);
    } else {
      await this.stopMusic();
    }
  }

  private async setMusic(music: HTMLAudioElement) {
    this.stopMusic();
    this.currentMusic = music;
    this.startMusic();
  }

  private async startMusic() {
    if (this.muted() || !this.currentMusic) {
      return;
    }
    try {
      this.currentMusic.loop = true;
      await this.currentMusic.play();
    } catch (e) {}
  }

  private stopMusic() {
    if (this.currentMusic) {
      this.currentMusic.pause();
      this.currentMusic.currentTime = 0;
    }
  }

  private muted() {
    return !this.sound$.getValue();
  }

  private async playSound(buffer: ArrayBufferLike): Promise<void> {
    try {
      const [sound] = await audioBufferToSource(buffer);
      sound.start();
    } catch (e) {}
  }
}
