import * as React from 'react';
import { Subscription } from 'rxjs';
import ObjectService from '../../../engine/services/ObjectService';
import SettingService from '../../../engine/services/SettingService';
import GameService from '../../../engine/services/GameService';
import { styled } from '../../../utils/styled-components';

interface IState {
  dilemmaTime: number;
  timer: number;
}

export default class Timer extends React.Component<object, IState> {
  public state = {
    dilemmaTime: 0,
    gameTime: new SettingService().get('gameTime'),
    timer: 0
  };

  private object = new ObjectService();
  private game = new GameService();
  private timeSub: Subscription | null = null;
  private dilemmaSub: Subscription | null = null;

  public componentDidMount() {
    this.timeSub = this.game.timer$.subscribe(this.checkIfTimerEnd.bind(this));

    this.dilemmaSub = this.object.dilemmaTime$.subscribe(
      this.setDilemmaTime.bind(this)
    );
  }

  public componentWillUnmount() {
    if (this.timeSub) {
      this.timeSub.unsubscribe();
    }
    if (this.dilemmaSub) {
      this.dilemmaSub.unsubscribe();
    }
  }

  public render() {
    const { timer, gameTime, dilemmaTime } = this.state;
    const dilemma = dilemmaTime >= 0 && dilemmaTime < 5;
    const time = dilemma ? dilemmaTime : gameTime - timer;

    return <TimeWrapper dilemma={dilemma}>{time}</TimeWrapper>;
  }

  private async checkIfTimerEnd(timer: number) {
    const { gameTime } = this.state;

    if (timer >= gameTime) {
      if (this.timeSub) {
        this.timeSub.unsubscribe();
      }
      await this.game.endGame();
    } else {
      this.setState({ timer });
    }
  }

  private setDilemmaTime(dilemmaTime: number) {
    this.setState({ dilemmaTime });
  }
}

const TimeWrapper = styled.div<{ dilemma: boolean }>`
  color: ${props => (props.dilemma ? props.theme.white : props.theme.white)};
`;
