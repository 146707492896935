import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule, ThemeProps } from 'styled-components';
import { ISettings } from '../engine/services/SettingService';

interface IThemeSettings {
  settings: ISettings;
}

export interface IThemeColors {
  grey: string;
  dilemma: string;
  blue: string;
  red: string;
  white: string;
  yellow: string;
  media: string;
  art: string;
  music: string;
  real: string;
  humanist: string;
}

export type ITheme = IThemeSettings & IThemeColors;

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
  createGlobalStyle
} = styledComponents as ThemedStyledComponentsModule<ITheme>;

export type IThemeProps = ThemeProps<ITheme>;

export { styled, css, createGlobalStyle, keyframes, ThemeProvider };
