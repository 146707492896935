import { styled } from '../utils/styled-components';

interface ISvgProps {
  y: number;
  fill: string;
}

const GameObject = styled.div<ISvgProps>`
  position: absolute;
  transform: translate3d(-5px, ${props => props.y}px, 0);
  transition: transform steps(${props => props.theme.settings.renderTime / 5})
    ${props => props.theme.settings.renderTime}ms;
  will-change: transform;
  height: calc(${props => props.theme.settings.actionWidth + 10}px);
  width: calc(${props => props.theme.settings.actionWidth + 10}px);

  backface-visibility: hidden;
  perspective: 1000;
`;

export default GameObject;
