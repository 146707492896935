import * as React from 'react';
import { getPercentFrom } from '../../utils/math';
import { GameObjectType } from '../../interfaces/gameObjects';
import { getObjectColor } from '../../utils/objects';
import { Strong } from '../../style-guide/Typography';
import { ICategoryChosen } from '../../engine/services/GameService';
import Flex from '../../style-guide/Flex';
import { keyframes, styled } from '../../utils/styled-components';
import { Art, Humanist, Media, Music, Real } from '../../style-guide/svg/Icons';

const FlexContainer = styled(Flex)`
  width: 100%;
`;

const heightAnimation = (height: number) => keyframes`
  from {
    height: 0;
  }
  
  to {
    height: ${height}px;
  }
`;

const PercentBar = styled.div<{ height: number; color: string }>`
  animation: ${props => heightAnimation(10 + props.height)} 2500ms both;
  animation-delay: 200ms;
  width: 1.2rem;
  background-color: ${props => props.color};
  transform: translateY(7px);
  z-index: 0;
  border-radius: 3px;
`;

const Circle = styled(Flex).attrs({
  item: true
})`
  z-index: 1;
`;

interface IProps {
  categories: ICategoryChosen;
}

const CourseGraph = ({ categories }: IProps) => {
  const { media, music, art, real, humanist } = getObjectHeights(categories);

  return (
    <FlexContainer container fullHeight align="flex-end">
      <Flex container item direction="column" align="center" justify="flex-end">
        <PercentBar height={media.height} color={media.color} />
        <Circle>
          <Media />
        </Circle>
        <Strong>{media.title}</Strong>
      </Flex>
      <Flex container item direction="column" align="center" justify="flex-end">
        <PercentBar height={art.height} color={art.color} />
        <Circle>
          <Art />
        </Circle>
        <Strong>{art.title}</Strong>
      </Flex>
      <Flex container item direction="column" align="center" justify="flex-end">
        <PercentBar height={music.height} color={music.color} />
        <Circle>
          <Music />
        </Circle>
        <Strong>{music.title}</Strong>
      </Flex>
      <Flex container item direction="column" align="center" justify="flex-end">
        <PercentBar height={real.height} color={real.color} />
        <Circle>
          <Real />
        </Circle>
        <Strong>{real.title}</Strong>
      </Flex>
      <Flex container item direction="column" align="center" justify="flex-end">
        <PercentBar height={humanist.height} color={humanist.color} />
        <Circle>
          <Humanist />
        </Circle>
        <Strong>{humanist.title}</Strong>
      </Flex>
    </FlexContainer>
  );
};

interface IObjectHeight {
  color: string;
  height: number;
  title: string;
}

interface IObjects {
  art: IObjectHeight;
  humanist: IObjectHeight;
  media: IObjectHeight;
  music: IObjectHeight;
  real: IObjectHeight;
}

function getObjectHeights(categories: ICategoryChosen) {
  const total = Object.keys(categories).reduce((t, c) => t + categories[c], 0);
  const getObjectValues = getHeightAndPercent(getPercentFrom(total));

  const objects: IObjects = {
    art: getObjectValues(GameObjectType.TWO, categories.Kunst),
    humanist: getObjectValues(GameObjectType.FIVE, categories.Studiespes),
    media: getObjectValues(GameObjectType.ONE, categories.Media),
    music: getObjectValues(GameObjectType.THREE, categories.Musikk),
    real: getObjectValues(GameObjectType.FOUR, categories.Real)
  };

  return addChangeBuffers(objects);
}

function getHeightAndPercent(getPercent: (x: number) => number) {
  return (type: GameObjectType, percent: number): IObjectHeight => {
    return {
      color: getObjectColor(type),
      height: getPercent(percent),
      title: type
    };
  };
}

function addChangeBuffers(objects: IObjects) {
  const highest = new Array(5).fill({ key: 'art', height: 0 });

  for (const key in objects) {
    if (objects.hasOwnProperty(key)) {
      const object: IObjectHeight = objects[key];

      for (let i = 0; i < highest.length; i++) {
        if (object.height >= highest[i].height) {
          highest[i + 1] = highest[i];
          highest[i] = { key, height: object.height };
          break;
        }
      }
    }
  }

  highest.forEach((high, index) => {
    if (high.height === 0) {
      return;
    }
    switch (index) {
      case 0:
        objects[high.key].height += 15;
        break;
      case 1:
        objects[high.key].height += 7;
        break;
      case 2:
        objects[high.key].height += 0;
        break;
      case 3:
        objects[high.key].height += -7;
        break;
      case 4:
        objects[high.key].height += -15;
        break;
    }
  });

  return objects;
}

export default CourseGraph;
