import * as React from 'react';
import ReactSVG from 'react-svg';
import './svg.css';

const Graphics = (icon: string, className: string = 'fullWidth') => {
  return () => <ReactSVG src={`graphics/${icon}.svg`} className={className} />;
};

const BgmDilemma = Graphics('bgmDilemma');
const BgmStandard = Graphics('bgmStandard');
const Bg = Graphics('bg');
const BgDilemma = Graphics('bgDilemma');

export default Graphics;
export { BgmDilemma, BgmStandard, Bg, BgDilemma };
