import * as React from 'react';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import Flex, { IFlexProps } from '../style-guide/Flex';
import GameService, { Pages } from '../engine/services/GameService';
import { Arrow } from '../style-guide/svg/Icons';
import { URL_STEINER_HOMEPAGE } from '../utils/constants';
import { H3, H4 } from '../style-guide/Typography';
import { styled } from '../utils/styled-components';

interface IProps extends IFlexProps {
  hideLink?: boolean;
}

interface IState {
  buttonDisabled: boolean;
}

export default class PlayAgain extends React.Component<IProps, IState> {
  public state = {
    buttonDisabled: true
  };

  private timeSub: Subscription | null = null;

  public componentDidMount() {
    this.timeSub = of([1])
      .pipe(delay(1500))
      .subscribe(() => {
        this.setState({ buttonDisabled: false });
      });
  }

  public componentWillUnmount(): void {
    if (this.timeSub) {
      this.timeSub.unsubscribe();
    }
  }

  public render() {
    const { hideLink, ...flexProps } = this.props;

    return (
      <Flex
        item
        container
        justify="center"
        align="center"
        direction="column"
        {...flexProps}
      >
        <StyledH3
          onClick={() => {
            if (!this.state.buttonDisabled) {
              new GameService().route(Pages.HOME);
            }
          }}
        >
          Prøv igjen!
        </StyledH3>
        {!hideLink && (
          <Flex container item align="center" gap={4}>
            <H4>eller les om skolen og alle våre linjer</H4>
            <a
              href={URL_STEINER_HOMEPAGE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Arrow />
            </a>
          </Flex>
        )}
      </Flex>
    );
  }
}

const StyledH3 = styled(H3)`
  text-decoration: underline;
`;
