import { interval, Observable, Subject, Subscription } from 'rxjs';
import SettingService, { ISettings } from './SettingService';

let instance: TimeService;

export default class TimeService {
  public timer$!: Observable<number>;
  public render$!: Observable<number>;
  public update$!: Observable<number>;
  public dilemma$!: Observable<number>;
  public timeUpdated$: Subject<number> = new Subject();

  private settings!: SettingService;
  private settingSub: Subscription | null = null;

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
    instance.settings = new SettingService();
    return instance;
  }

  public startGameTimer() {
    this.settingSub = this.settings.$.subscribe(this.update.bind(instance));
    this.timer$ = interval(1000);
    return this.timer$;
  }

  public stopGameTimer() {
    if (this.settingSub) {
      this.settingSub.unsubscribe();
    }
    delete this.timer$;
  }

  public startDilemmaTimer() {
    this.dilemma$ = interval(1000);
  }

  public stopDilemmaTimer() {
    delete this.dilemma$;
  }

  private update(settings: ISettings) {
    this.render$ = interval(settings.renderTime);
    this.update$ = interval(settings.updateTime);
    this.timeUpdated$.next(settings.updateTime);
  }
}
