import * as React from 'react';
import Flex from './Flex';
import { Close } from './svg/Icons';
import { styled } from '../utils/styled-components';

const CloseIcon = styled(Flex)`
  position: absolute;
  transform: translateY(-40px) translateX(30px);
  align-self: flex-end;
  cursor: pointer;
`;

export default ({ onClick }: { onClick: () => void }) => (
  <CloseIcon item onClick={onClick}>
    <Close />
  </CloseIcon>
);
