import * as React from 'react';
import ReactSVG from 'react-svg';
import './svg.css';
import { getRandomNumber } from '../../utils/random';

const Icons = (icon: string, className: string = 'fullWidth') => {
  return () => <ReactSVG src={`music/${icon}.svg`} className={className} />;
};

const Book = Icons('book');
const Pen = Icons('pen');

const Music1 = Icons('mic');
const Music2 = Icons('note');
const Music3 = Icons('piano');

export default (num = getRandomNumber(0, 4)) => {
  switch (num) {
    case 0:
      return Music1;
    case 1:
      return Music2;
    case 2:
      return Music3;
    case 3:
      return Book;
    default:
      return Pen;
  }
};
