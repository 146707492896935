import { getScreenHeight, getScreenWidth } from './window';
import { ISettings } from '../engine/services/SettingService';

const backgroundGradients: string[] = [
  'linear-gradient(147deg, #56cffb 17%, #85ffbd 78%)',
  'linear-gradient(0deg, #ffee8e 0%, #ff5763 64%)'
];

const MAX_SCREEN_WIDTH = 500;
const MAX_DILEMMAS = 3;
export const COMBO_START = 10;

const URL_STEINER_HOMEPAGE = 'https://oslo-bysteinerskole.no';
const URL_STEINER_MEDIA = 'https://oslo-bysteinerskole.no/media';
const URL_STEINER_ART = 'https://oslo-bysteinerskole.no/kunstfag';
const URL_STEINER_MUSIC = 'https://oslo-bysteinerskole.no/musikk';
const URL_STEINER_REAL = 'https://oslo-bysteinerskole.no/studiespesreal';
const URL_STEINER_HUMANIST = 'https://oslo-bysteinerskole.no/studiespes';

const END_TEXT_MEDIA =
  'Film, design, animasjon, instagram ... Du sier «ja, takk» til alt sammen?';
const END_TEXT_ART = 'Jasså, kanskje er det du som åpner galleri en dag?';
const END_TEXT_MUSIC =
  'Se der ja, du er en kommende singer/songwriter eller producer?';
const END_TEXT_REAL = 'Er du av den nysgjerrige typen kanskje?';
const END_TEXT_HUMAN = 'To be or not to be ... Det er helt opp til deg.';

const defaultSettings: ISettings = {
  actionWidth: 62,
  bottomMargin: 40,
  gameTime: 45,
  height: getScreenHeight(),
  points: 0,
  renderTime: 2000,
  updateTime: 500,
  width: getScreenWidth()
};

const HOW_TO_PLAY = `På 45 sekunder skal du treffe linjen perfekt med så mange elementer som mulig.
Men vær forberedt på dilemma, her må du ta et valg.`;
const HOW_TO_PLAY_DESKTOP = `Spill med musen eller bruk tastene`;
const HOW_TO_PLAY_SHORTCUTS = 'Z, X, space, komma og punktum.';
const HOW_TO_PLAY_END = `Takler du linjestresset?`;

const FACEBOOK_SHARE = (points: number, placement: number) =>
  `Jeg fikk ${points} og ${placement} plass. Klarer du å slå min score?`;

export {
  backgroundGradients,
  defaultSettings,
  MAX_DILEMMAS,
  MAX_SCREEN_WIDTH,
  URL_STEINER_ART,
  URL_STEINER_HOMEPAGE,
  URL_STEINER_HUMANIST,
  URL_STEINER_MEDIA,
  URL_STEINER_MUSIC,
  URL_STEINER_REAL,
  HOW_TO_PLAY,
  HOW_TO_PLAY_DESKTOP,
  HOW_TO_PLAY_SHORTCUTS,
  HOW_TO_PLAY_END,
  END_TEXT_MEDIA,
  END_TEXT_ART,
  END_TEXT_HUMAN,
  END_TEXT_MUSIC,
  END_TEXT_REAL,
  FACEBOOK_SHARE
};
