import { styled } from '../utils/styled-components';

const Button = styled.button`
  width: 100%;
  padding: 0 2rem;
  height: 4rem;
  border: none;
  border-radius: 0.5rem;
  text-align: center;
  background-color: #ff5763;
  box-shadow: 1px 3px 10px grey;

  color: white;
  font-weight: 600;
  font-size: 1.3rem;
  text-transform: uppercase;
  cursor: pointer;
`;

export default Button;
