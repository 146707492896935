import * as React from 'react';

import ObjectContainer from './gameBoard/ObjectContainer';
import Actions from './gameBoard/Actions';
import ObjectIcons from './gameBoard/ObjectIcons';
import Header from './gameBoard/Header';
import { GameObjectType, IGameRow } from '../interfaces/gameObjects';

interface IProps {
  rows: IGameRow[];
}

export default class GameBoard extends React.Component<IProps> {
  public static defaultProps = {
    rows: [
      { type: GameObjectType.ONE, color: 'media', actionKey: 'z' },
      { type: GameObjectType.TWO, color: 'art', actionKey: 'x' },
      { type: GameObjectType.THREE, color: 'music', actionKey: ' ' },
      { type: GameObjectType.FOUR, color: 'real', actionKey: ',' },
      { type: GameObjectType.FIVE, color: 'humanist', actionKey: '.' }
    ]
  };

  public render() {
    const { rows } = this.props;

    return (
      <React.Fragment>
        <Header />
        <ObjectIcons rows={rows} />
        <ObjectContainer />
        <Actions rows={rows} />
      </React.Fragment>
    );
  }
}
