import * as React from 'react';
import ReactSVG from 'react-svg';
import './svg.css';
import { getRandomNumber } from '../../utils/random';

const Icons = (icon: string, className: string = 'fullWidth') => {
  return () => <ReactSVG src={`media/${icon}.svg`} className={className} />;
};

const Book = Icons('book');
const Pen = Icons('pen');

const Camera = Icons('camera');
const Movie = Icons('movie');
const Paper = Icons('paper');

export default (num = getRandomNumber(0, 4)) => {
  switch (num) {
    case 0:
      return Book;
    case 1:
      return Camera;
    case 2:
      return Movie;
    case 3:
      return Paper;
    default:
      return Pen;
  }
};
