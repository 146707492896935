export function calculateCenter(x: number, y: number, w: number, h: number) {
  const w2 = w / 2;
  const h2 = h / 2;
  return {
    x: x + w2,
    y: y + h2
  };
}

export function calculateRadius(x: number, y: number, w: number, h: number) {
  return w / 2;
}

export function getRandomInt(min: number = 0, max: number = 5) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getPercentFrom(total: number) {
  return (x: number) => {
    if (x === 0) {
      return 0;
    }
    return Math.floor((100 * x) / total);
  };
}
