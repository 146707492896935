import * as React from 'react';
import { ChangeEvent } from 'react';
import InfoWindow from '../components/InfoWindow';
import SoundToggle from '../components/SoundToggle';
import ButtonIcon from '../style-guide/ButtonIcon';
import {
  HOW_TO_PLAY,
  HOW_TO_PLAY_DESKTOP,
  HOW_TO_PLAY_END,
  HOW_TO_PLAY_SHORTCUTS,
  URL_STEINER_HOMEPAGE
} from '../utils/constants';
import { Fullscreen, Help } from '../style-guide/svg/Icons';
import SoundService from '../engine/services/SoundService';
import { H2, H4, Error } from '../style-guide/Typography';
import Layer from '../engine/Layer';
import { isIOS, isMobile, toggleFullScreen } from '../utils/window';
import Logo from '../style-guide/Logo';
import Input from '../style-guide/Input';
import GameService from '../engine/services/GameService';
import Button from '../style-guide/Button';
import Flex from '../style-guide/Flex';
import SteinerLogo from '../style-guide/SteinerLogo';
import { styled } from '../utils/styled-components';

interface IState {
  name: string;
  noName: boolean;
  showInfo: boolean;
}

export default class Home extends React.Component<object, IState> {
  public game = new GameService();

  public state = {
    name: this.game.nick.getValue() || '',
    noName: false,
    showInfo: false
  };

  private sound = new SoundService();

  constructor(props: object) {
    super(props);
    this.startGame = this.startGame.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  public componentDidMount() {
    this.sound.setMenuMusic(true);
  }

  public render() {
    const { showInfo } = this.state;
    return (
      <Layer>
        <Flex container direction="column" justify="space-between" fullHeight>
          <Flex container item justify="center" align="center" flex=".5 1 auto">
            <Flex item xs={11} flex=".5 1 auto">
              <Logo />
            </Flex>
          </Flex>
          {showInfo ? this.renderInfo() : this.renderStartGame()}
          <FooterWrapper
            container
            item
            align="center"
            justify="space-between"
            flex=".5 1 auto"
          >
            <Flex item flex=".9 1 auto">
              <a
                href={URL_STEINER_HOMEPAGE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SteinerLogo />
              </a>
            </Flex>
            <Flex item flex=".1 1 auto">
              <MusicH4>Musikk av: Gaël Peebles</MusicH4>
            </Flex>
          </FooterWrapper>
        </Flex>
      </Layer>
    );
  }

  private renderInfo() {
    return (
      <MainContent container item justify="center">
        <Flex item flex=".5 1 auto" xs={8}>
          <InfoWindow onClose={this.toggleInfo}>
            <ScrollContainer>
              <InfoContent container direction="column" gap={16}>
                <StyledH2>Slik spiller du</StyledH2>
                <StyledH4>{HOW_TO_PLAY}</StyledH4>
                {!isMobile() && (
                  <StyledH4>
                    {HOW_TO_PLAY_DESKTOP} <br /> {HOW_TO_PLAY_SHORTCUTS}
                  </StyledH4>
                )}
                <StyledH4>{HOW_TO_PLAY_END}</StyledH4>
              </InfoContent>
            </ScrollContainer>
          </InfoWindow>
        </Flex>
      </MainContent>
    );
  }

  private renderStartGame() {
    const { name, noName } = this.state;
    return (
      <MainContent
        container
        item
        direction="column"
        justify="center"
        alignself="center"
        gap={12}
        gapDivider={1}
      >
        <Flex item flex="0 1 auto">
          <Input
            type="text"
            value={name}
            onChange={this.handleNameChange}
            placeholder="Ditt kallenavn"
            required
          />
          {noName && <Error>Trenger navn</Error>}
        </Flex>
        <Flex item flex="0 1 auto">
          <Button onClick={this.startGame}>Start spillet!</Button>
        </Flex>
        <Flex
          container
          item
          flex="0 1 auto"
          justify="center"
          align="center"
          gap={8}
        >
          <Flex item flex="0 1 auto">
            <ButtonIcon onClick={this.toggleInfo} padding=".1rem 0">
              <Help />
            </ButtonIcon>
          </Flex>
          <Flex item flex="0 1 auto">
            <SoundToggle />
          </Flex>
          {!isIOS() && (
            <Flex item flex="0 1 auto" onClick={() => toggleFullScreen()}>
              <Fullscreen />
            </Flex>
          )}
        </Flex>
      </MainContent>
    );
  }

  private toggleInfo() {
    this.setState({ showInfo: !this.state.showInfo });
  }

  private handleNameChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      name: e.target.value,
      noName: e.target.value.length === 0
    });
  }

  private startGame() {
    const { name } = this.state;
    if (name === '') {
      this.setState({ noName: true });
      return;
    }
    this.game.startGame(name);
  }
}

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const MainContent = styled(Flex)`
  height: 40%;
`;

const InfoContent = styled(Flex)`
  padding: 2rem 1rem 1rem 1rem;
`;

const StyledH2 = styled(H2)`
  font-size: 2rem;
`;

const StyledH4 = styled(H4)`
  font-weight: normal;
  font-size: 1.5rem;
`;

const FooterWrapper = styled(Flex)`
  margin: 0 2rem;
`;

const MusicH4 = styled(H4)`
  @media all and (max-width: 500px) {
    font-size: 0.8rem;
  }
`;
