import * as firebase from 'firebase';
import 'firebase/database';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET
};

const fireBase = firebase.initializeApp(config).database();

interface IFirebaseBody {
  name: string;
  score: number;
  topCourse: string;
}

export function createUser(path: string, body: IFirebaseBody) {
  if (!path || !body) {
    return;
  }

  return new Promise((res, rej) => {
    fireBase
      .ref(path)
      .set(body)
      .then(res, rej);
  });
}

export function readUsers(path: string) {
  if (!path) {
    return;
  }

  return new Promise((res, rej) => {
    fireBase
      .ref(path)
      .once('value')
      .then(snapshot => {
        res(snapshot.val());
      }, rej);
  });
}

export async function getAllHighscores() {
  const path = 'users/';

  try {
    const test: any = await readUsers(path);
    if (!test) {
      return [];
    }

    const allPoints = Object.keys(test).map((item, i) => {
      return { ...test[item], user: item };
    });

    allPoints.sort((a, b) => {
      return b.score - a.score;
    });

    return allPoints;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export default fireBase;
