import * as React from 'react';
import { Subscription } from 'rxjs';
import Combo from './Combo';
import Accurate from './Accurate';
import GameBackground from '../views/gameBoard/objectIcons/GameBackground';
import GameService, { Pages } from '../engine/services/GameService';
import ObjectService from '../engine/services/ObjectService';
import Dilemma from '../style-guide/Dilemma';
import Flex from '../style-guide/Flex';
import Layer from '../engine/Layer';
import { keyframes, styled } from '../utils/styled-components';

interface IState {
  animate: boolean;
  combo: number;
  isDilemma: boolean;
  page: Pages;
  prevCombo: number;
}

export default class Background extends React.Component<object, IState> {
  public state = {
    animate: false,
    combo: 0,
    isDilemma: false,
    page: Pages.HOME,
    prevCombo: 0
  };

  private game = new GameService();
  private object = new ObjectService();

  private pageSub: Subscription | null = null;
  private backgroundSub: Subscription | null = null;

  public componentDidMount() {
    this.backgroundSub = this.object.dilemma$.subscribe(
      this.updateBackground.bind(this)
    );
    this.pageSub = this.game.page.subscribe(this.setPage.bind(this));
  }

  public componentWillUnmount() {
    if (this.backgroundSub) {
      this.backgroundSub.unsubscribe();
    }
    if (this.pageSub) {
      this.pageSub.unsubscribe();
    }
  }

  public render() {
    const { isDilemma, page, animate } = this.state;
    return (
      <React.Fragment>
        {page === Pages.PLAY && (
          <Layer>
            <GameBackground />
          </Layer>
        )}
        <Layer zIndex={0}>
          <StyledText
            container
            fullHeight
            direction="column"
            align="center"
            justify="space-around"
          >
            <Flex item flex="0 1 auto">
              {isDilemma && <Dilemma />}
            </Flex>
          </StyledText>
          <StyledBackground
            animate={true}
            isDilemma={true}
            style={{ backgroundImage: 'url("bg.jpg")' }}
          />
          <StyledBackground
            animate={animate}
            isDilemma={isDilemma}
            style={{ backgroundImage: 'url("dilemmaBG.png")' }}
          />
        </Layer>
        {page === Pages.PLAY && (
          <Layer>
            <CenterWrapper bottom="30%">
              <Combo isDilemma={isDilemma} />
            </CenterWrapper>
            <CenterWrapper bottom="20%">
              <Accurate />
            </CenterWrapper>
          </Layer>
        )}
      </React.Fragment>
    );
  }

  private updateBackground(isDilemma: boolean) {
    if (this.state.isDilemma !== isDilemma) {
      this.setState({ isDilemma, animate: true });
    }
  }

  private setPage(page: Pages) {
    this.setState({ page });
  }
}

const backgroundFade = (isDilemma: boolean) => keyframes`
  from {
    opacity: ${isDilemma ? 0 : 1}
  }

  to {
    opacity: ${isDilemma ? 1 : 0}
  }
`;

interface StyledBackgroundProps {
  animate: boolean;
  isDilemma: boolean;
  bg: string;
}
const StyledBackground = styled.div<StyledBackgroundProps>`
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: ${props => (props.animate ? 1 : 0)};
  animation: ${props => props.animate && backgroundFade(props.isDilemma)} 1000ms
    both;
  width: 100%;
  height: 100%;
`;

const StyledText = styled(Flex)`
  width: 100%;
  position: absolute;
  background: transparent;
  z-index: 1;
`;

const CenterWrapper = styled.div<{ bottom: string }>`
  position: absolute;
  bottom: ${props => props.bottom};
  width: 100%;
  text-align: center;
`;
