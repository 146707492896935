import { styled } from '../utils/styled-components';

interface IProps {
  background: string;
  noBorder?: boolean;
}

const Clickable = styled.div<IProps>`
  width: ${props => props.theme.settings.actionWidth}px;
  height: ${props => props.theme.settings.actionWidth}px;
  padding: 4rem 0;
  margin-top: -4rem;
  border: ${props => (props.noBorder ? 0 : 5)}px solid transparent;
  background-color: ${props => props.background};
  cursor: pointer;
  &focus {
    outline: 0;
  }
`;

export default Clickable;
