import * as React from 'react';
import { Subscription } from 'rxjs';
import GameService from '../../engine/services/GameService';
import { H2 } from '../../style-guide/Typography';
import { styled } from '../../utils/styled-components';

interface IState {
  placement: number;
}

const StyledPosition = styled(H2)`
  font-weight: normal;
  text-transform: initial;
`;

export default class Placement extends React.Component<object, IState> {
  public state = {
    placement: -1
  };

  private game = new GameService();
  private placementSub: Subscription | null = null;

  public componentDidMount() {
    this.placementSub = this.game.placement$.subscribe(placement => {
      this.setState({ placement });
    });
  }

  public componentWillUnmount() {
    if (this.placementSub) {
      this.placementSub.unsubscribe();
    }
  }

  public render() {
    return <StyledPosition>{this.state.placement + 1}. plass</StyledPosition>;
  }
}
