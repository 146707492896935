import React, { useMemo } from 'react';
import { styled } from '../utils/styled-components';

const DesktopBackground = styled.div`
  // background-image: url('bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  transform: rotate(180deg);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media (max-width: 500px) {
    display: none;
  }
`;

export default function() {
  return useMemo(
    () => <DesktopBackground style={{ backgroundImage: 'url("bg.jpg")' }} />,
    []
  );
}
