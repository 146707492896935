import { styled } from '../utils/styled-components';

const Logo = styled.img.attrs({
  src: 'Logo_linjestress.png'
})`
  width: 100%;
  height: auto;
`;

export default Logo;
