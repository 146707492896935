import { styled } from '../utils/styled-components';

const SteinerLogo = styled.img.attrs({
  src: 'Logo_osloby.png'
})`
  width: 150px;
  height: auto;
`;

export default SteinerLogo;
