import { GameObjectType } from '../interfaces/gameObjects';
import {
  END_TEXT_ART,
  END_TEXT_HUMAN,
  END_TEXT_MEDIA,
  END_TEXT_MUSIC,
  END_TEXT_REAL,
  URL_STEINER_ART,
  URL_STEINER_HUMANIST,
  URL_STEINER_MEDIA,
  URL_STEINER_MUSIC,
  URL_STEINER_REAL
} from './constants';
import { getRandomInt } from './math';
import theme from './theme';
import Media from '../style-guide/svg/Media';
import Art from '../style-guide/svg/Art';
import Music from '../style-guide/svg/Music';
import Real from '../style-guide/svg/Real';
import Humanist from '../style-guide/svg/Humanist';

export function getObjectNumber(type: GameObjectType): number {
  switch (type) {
    case GameObjectType.ONE:
      return 0;
    case GameObjectType.TWO:
      return 1;
    case GameObjectType.THREE:
      return 2;
    case GameObjectType.FOUR:
      return 3;
    case GameObjectType.FIVE:
      return 4;
    default:
      return 5;
  }
}

export function getObjectFromNumber(num: number): GameObjectType {
  switch (num) {
    case 0:
      return GameObjectType.ONE;
    case 1:
      return GameObjectType.TWO;
    case 2:
      return GameObjectType.THREE;
    case 3:
      return GameObjectType.FOUR;
    case 4:
      return GameObjectType.FIVE;
    default:
      return GameObjectType.ALL;
  }
}

export function getObjectColor(type: GameObjectType | string): string {
  switch (type) {
    case GameObjectType.ONE:
      return theme.media;
    case GameObjectType.TWO:
      return theme.art;
    case GameObjectType.THREE:
      return theme.music;
    case GameObjectType.FOUR:
      return theme.real;
    case GameObjectType.FIVE:
      return theme.humanist;
    default:
      return theme.media;
  }
}

export function getObjectType(type: string): GameObjectType {
  switch (type) {
    case GameObjectType.ONE:
      return GameObjectType.ONE;
    case GameObjectType.TWO:
      return GameObjectType.TWO;
    case GameObjectType.THREE:
      return GameObjectType.THREE;
    case GameObjectType.FOUR:
      return GameObjectType.FOUR;
    case GameObjectType.FIVE:
      return GameObjectType.FIVE;
    default:
      return GameObjectType.ONE;
  }
}

export function getObjectIcon(type: GameObjectType) {
  switch (type) {
    case GameObjectType.ONE:
      return Media();
    case GameObjectType.TWO:
      return Art();
    case GameObjectType.THREE:
      return Music();
    case GameObjectType.FOUR:
      return Real();
    case GameObjectType.FIVE:
      return Humanist();
    default:
      return Media();
  }
}

export function getObjectUrl(type: string | GameObjectType) {
  switch (type) {
    case GameObjectType.ONE:
      return URL_STEINER_MEDIA;
    case GameObjectType.TWO:
      return URL_STEINER_ART;
    case GameObjectType.THREE:
      return URL_STEINER_MUSIC;
    case GameObjectType.FOUR:
      return URL_STEINER_REAL;
    case GameObjectType.FIVE:
      return URL_STEINER_HUMANIST;
    default:
      return URL_STEINER_MEDIA;
  }
}

export function getObjectText(type: string | GameObjectType) {
  switch (type) {
    case GameObjectType.ONE:
      return END_TEXT_MEDIA;
    case GameObjectType.TWO:
      return END_TEXT_ART;
    case GameObjectType.THREE:
      return END_TEXT_MUSIC;
    case GameObjectType.FOUR:
      return END_TEXT_REAL;
    case GameObjectType.FIVE:
      return END_TEXT_HUMAN;
    default:
      return END_TEXT_MEDIA;
  }
}

export function getRandomObject(): GameObjectType {
  return getObjectFromNumber(getRandomInt(0, 5));
}
