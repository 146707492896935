import * as React from 'react';
import Timer from './header/Timer';
import SoundToggle from '../../components/SoundToggle';
import Score from './header/Score';
import { Subscription } from 'rxjs';
import ObjectService from '../../engine/services/ObjectService';
import GameService from '../../engine/services/GameService';
import Svg from '../../style-guide/Svg';
import Flex from '../../style-guide/Flex';
import { Restart } from '../../style-guide/svg/Icons';
import Layer from '../../engine/Layer';
import { styled } from '../../utils/styled-components';

const StyledHeader = styled(Flex)<{ dilemma: boolean }>`
  height: 72px;
  background-color: ${props =>
    props.dilemma ? props.theme.dilemma : props.theme.blue};
  transition: background-color 1000ms ease-in-out;
`;

const StyledTimer = styled(Flex)<{ dilemma: boolean }>`
  width: 75px;
  height: 75px;
  transform: translateY(25px);
  color: ${props => (props.dilemma ? props.theme.white : props.theme.white)};
  border-radius: 50%;
  background-color: ${props =>
    props.dilemma ? props.theme.dilemma : props.theme.blue};
  transition: background-color 1000ms ease-in-out;
  font-weight: 600;
  font-size: 1.4rem;
`;

const AlignedTimer = styled.div`
  transform: translateY(15px);
`;

interface IState {
  dilemma: boolean;
}

export default class Header extends React.Component<object, IState> {
  public state = {
    dilemma: false
  };

  private game = new GameService();
  private object = new ObjectService();
  private dilemmaSub: Subscription | null = null;

  constructor(props: object) {
    super(props);
    this.restartGame = this.restartGame.bind(this);
    this.handleDilemma = this.handleDilemma.bind(this);
  }

  public componentDidMount() {
    this.dilemmaSub = this.object.dilemma$.subscribe(this.handleDilemma);
  }

  public componentWillUnmount() {
    if (this.dilemmaSub) {
      this.dilemmaSub.unsubscribe();
    }
  }

  public render() {
    const { dilemma } = this.state;
    return (
      <Layer height="60px" zIndex={2}>
        <StyledHeader
          container
          justify="space-around"
          align="center"
          dilemma={dilemma}
        >
          <Flex container item justify="flex-start" xs={4}>
            <Score />
          </Flex>

          <Flex container item justify="center" xs={4}>
            <StyledTimer
              container
              justify="center"
              align="center"
              dilemma={dilemma}
            >
              <AlignedTimer>
                <Timer />
              </AlignedTimer>
            </StyledTimer>
          </Flex>

          <Flex container item justify="flex-end" xs={4}>
            <Flex item>
              <SoundToggle variant="secondary" />
            </Flex>
            <Flex item>
              <Svg onClick={this.restartGame}>
                <Restart />
              </Svg>
            </Flex>
          </Flex>
        </StyledHeader>
      </Layer>
    );
  }

  private async restartGame() {
    await this.game.endGame(true);
  }

  private handleDilemma(dilemma: boolean) {
    this.setState({ dilemma });
  }
}
