import {
  ContentDistribution,
  ContentPosition,
  FlexDirectionProperty,
  FlexWrapProperty
} from 'csstype';
import Layer from '../engine/Layer';
import { css, styled } from '../utils/styled-components';

type IGrid = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type IPosition = ContentPosition | ContentDistribution;

interface IShared {
  fullHeight?: boolean;
}

interface IItem {
  item?: boolean;
  flex?: string;
  alignself?: IPosition;
  xs?: IGrid;
}

interface IContainer {
  container?: boolean;
  direction?: FlexDirectionProperty;
  justify?: IPosition;
  align?: IPosition;
  gap?: number;
  gapDivider?: number;
  wrap?: FlexWrapProperty;
}

export type IFlexProps = IShared & IContainer & IItem;

const gapRow = css<IContainer>`
  margin-left: -${props => props.gap}px;
  margin-right: -${props => props.gap}px;
  > * {
    padding-left: ${props => props.gap}px;
    padding-right: ${props => props.gap}px;
  }
`;

const gapColumn = css<{ gap: number; gapDivider: number }>`
  @media (min-height: 699px) {
    margin-top: -${props => props.gap}px;
    margin-bottom: -${props => props.gap}px;
    > * {
      padding-top: ${props => props.gap}px;
      padding-bottom: ${props => props.gap}px;
    }
  }

  @media (max-height: 700px) {
    margin-top: -${props => props.gap / props.gapDivider || 4}px;
    margin-bottom: -${props => props.gap / props.gapDivider || 4}px;
    > * {
      padding-top: ${props => props.gap / props.gapDivider || 4}px;
      padding-bottom: ${props => props.gap / props.gapDivider || 4}px;
    }
  }
`;

const container = css<IContainer>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'stretch'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  ${props => props.gap && (props.direction === 'column' ? gapColumn : gapRow)};
`;

const item = css<IItem>`
  flex: ${props => props.flex || '1 1 auto'};
  box-sizing: border-box;
  align-self: auto;
  ${props => props.alignself && `align-self: ${props.alignself}`};
  ${props => props.xs && `flex-basis: ${(props.xs / 12) * 100}%;`};
`;

const Flex = styled.div<IFlexProps>`
  ${props => props.container && container};
  ${props => props.item && item};
  ${props => props.fullHeight && 'height: 100%;'};
`;

const BasedLayer = styled(Layer)`
  height: ${props => props.theme.settings.actionWidth}px;
  bottom: ${props => props.theme.settings.bottomMargin}px;
`;

export default Flex;

export { BasedLayer };
