import { IThemeColors } from './styled-components';

const theme: IThemeColors = {
  art: '#ABBE6C',
  blue: '#63A6BC',
  dilemma: '#FF5864',
  grey: '#5A5A5A',
  humanist: '#F5AF40',
  media: '#4B8DC7',
  music: '#AB8BBE',
  real: '#F09DA3',
  red: '#FF5864',
  white: '#fff',
  yellow: '#FFEF43'
};

export default theme;
