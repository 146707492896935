import * as React from 'react';
import ReactSVG from 'react-svg';
import './svg.css';
import { getRandomNumber } from '../../utils/random';

const Icons = (icon: string, className: string = 'fullWidth') => {
  return () => <ReactSVG src={`art/${icon}.svg`} className={className} />;
};

const Art1 = Icons('art1');
const Art2 = Icons('art2');
const Art3 = Icons('art3');
const Book = Icons('book');
const Pen = Icons('pen');

export default (num = getRandomNumber(0, 4)) => {
  switch (num) {
    case 0:
      return Art1;
    case 1:
      return Art2;
    case 2:
      return Art3;
    case 3:
      return Book;
    default:
      return Pen;
  }
};
