import * as React from 'react';
import Music from '../style-guide/svg/Music';
import Media from '../style-guide/svg/Media';
import Art from '../style-guide/svg/Art';
import Real from '../style-guide/svg/Real';
import Humanist from '../style-guide/svg/Humanist';

export default function PreloadSvg() {
  const arr = [0, 1, 2, 3, 4];

  return (
    <div>
      {arr.map(renderComponent(Real))}
      {arr.map(renderComponent(Music))}
      {arr.map(renderComponent(Media))}
      {arr.map(renderComponent(Art))}
      {arr.map(renderComponent(Humanist))}
    </div>
  );
}

function renderComponent(func: (num: number) => () => JSX.Element) {
  return (num: number) => {
    const A = func(num);
    return (
      <div
        key={num}
        style={{
          position: 'fixed',
          top: 0,
          width: '0px'
        }}
      >
        <A />
      </div>
    );
  };
}
