import * as React from 'react';
import ReactSVG from 'react-svg';
import './svg.css';
import { getRandomNumber } from '../../utils/random';

const Icons = (icon: string, className: string = 'fullWidth') => {
  return () => <ReactSVG src={`humanist/${icon}.svg`} className={className} />;
};

const Book = Icons('book');
const Pen = Icons('pen');

const Humanist1 = Icons('earth');
const Humanist2 = Icons('scale');
const Humanist3 = Icons('think');

export default (num = getRandomNumber(0, 4)) => {
  switch (num) {
    case 0:
      return Humanist1;
    case 1:
      return Humanist2;
    case 2:
      return Humanist3;
    case 3:
      return Book;
    default:
      return Pen;
  }
};
