import * as React from 'react';
import { Subscription } from 'rxjs';
import GameService from '../engine/services/GameService';
import { IAnimation, noAnimation } from '../style-guide/animation/pulse';
import { COMBO_START } from '../utils/constants';
import { H1 } from '../style-guide/Typography';
import PulseComponent from '../style-guide/animation/PulseComponent';
import { styled } from '../utils/styled-components';

interface IProps {
  isDilemma: boolean;
}

interface IState {
  combo: number;
}

export default class Combo extends PulseComponent<IProps, IState> {
  public state = {
    animating: false,
    animation: noAnimation,
    combo: 0
  };

  private game = new GameService();
  private comboSub: Subscription | null = null;

  public componentDidMount() {
    this.comboSub = this.game.combo$.subscribe(this.updateCombo.bind(this));
  }

  public componentWillUnmount() {
    if (this.comboSub) {
      this.comboSub.unsubscribe();
    }
  }

  public render() {
    const { isDilemma } = this.props;
    const { combo, animating, animation } = this.state;

    return (
      combo >= COMBO_START && (
        <StyledCombo
          isDilemma={isDilemma}
          animating={animating}
          animation={animation}
        >
          x{combo}
        </StyledCombo>
      )
    );
  }

  private updateCombo(combo: number) {
    if (this.state.combo === combo) {
      return;
    }

    this.startAnimation({ combo });
  }
}

interface ICombo extends IAnimation {
  isDilemma: boolean;
}

const StyledCombo = styled(H1)<ICombo>`
  color: ${props => (props.isDilemma ? props.theme.blue : props.theme.red)};
  font-size: 2.25rem;
  font-weight: 700;
  text-align: center;
  animation: ${props => props.animation} 100ms both;
  will-change: scale;
  text-transform: lowercase;
`;
