import * as React from 'react';
import { Subscription } from 'rxjs';
import ObjectService from '../../../engine/services/ObjectService';
import { BgmDilemma } from '../../../style-guide/svg/Graphics';
import { keyframes, styled } from '../../../utils/styled-components';

const fadeIn = (isDilemma: boolean) => keyframes`
  from {
    opacity: ${isDilemma ? 0 : 1};
  }
  
  to {
    opacity: ${isDilemma ? 1 : 0};
  }
`;

const Wrapper = styled.div<{ animate: boolean; isDilemma: boolean }>`
  width: 100%;
  bottom: -5px;
  position: absolute;
  transform: translateY(0);
  opacity: 0;
  animation: ${props => props.animate && fadeIn(props.isDilemma)} 200ms both;
  z-index: 0;
`;

interface IState {
  animate: boolean;
  isDilemma: boolean;
}

export default class GameBackground extends React.Component<object, IState> {
  public state = {
    animate: false,
    isDilemma: false
  };

  private objects = new ObjectService();
  private dilemmaSub: Subscription | null = null;

  public componentDidMount() {
    this.dilemmaSub = this.objects.dilemma$.subscribe(
      this.setDilemma.bind(this)
    );
  }

  public componentWillUnmount() {
    if (this.dilemmaSub) {
      this.dilemmaSub.unsubscribe();
    }
  }

  public render() {
    const { isDilemma, animate } = this.state;
    return (
      <Wrapper isDilemma={isDilemma} animate={animate}>
        <BgmDilemma />
      </Wrapper>
    );
  }

  private setDilemma(isDilemma: boolean) {
    if (this.state.isDilemma !== isDilemma) {
      this.setState({ isDilemma, animate: true });
    }
  }
}
