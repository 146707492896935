import { styled } from '../utils/styled-components';

const Info = styled.div<{ shadow?: string }>`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0.5rem;
  background-color: white;
  text-align: center;
  box-shadow: ${props =>
    props.shadow ? props.shadow : `0 3px 10px ${props.theme.grey}`};
`;

export default Info;
