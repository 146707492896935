import * as React from 'react';
import ReactSVG from 'react-svg';
import './svg.css';

const Icons = (icon: string, className: string = 'small') => {
  return () => <ReactSVG src={`icons/${icon}.svg`} className={className} />;
};

const Help = Icons('help', 'extraSmall');
const Sound = Icons('sound');
const Trophy = Icons('trophy');
const TrophyCircle = Icons('trophyCircle', 'large-shrink-mobile');
const SoundOn = Icons('soundOn', 'medium');
const SoundOff = Icons('soundOff', 'medium');
const Restart = Icons('restart', 'medium');
const ArrowCircle = Icons('arrowCircle', 'medium');
const Facebook = Icons('facebook', 'medium');
const Close = Icons('close', 'medium');
const MedalCircle = Icons('medalCircle', 'medium');
const Medal = Icons('medal', 'medium');
const Arrow = Icons('arrow', 'small-medium');
const Fullscreen = Icons('fullscreen', 'medium');

const Art = Icons('art', 'medium');
const Humanist = Icons('humanist', 'medium');
const Media = Icons('media', 'medium');
const Music = Icons('music', 'medium');
const Real = Icons('real', 'medium');

export default Icons;
export {
  Arrow,
  Medal,
  MedalCircle,
  Facebook,
  SoundOff,
  SoundOn,
  Restart,
  Trophy,
  TrophyCircle,
  Help,
  Sound,
  ArrowCircle,
  Close,
  Art,
  Humanist,
  Real,
  Media,
  Music,
  Fullscreen
};
