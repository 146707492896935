import { keyframes } from '../../utils/styled-components';

export interface IAnimation {
  animation: string;
  animating: boolean;
}

const noAnimation = keyframes``;

const pulseIn = keyframes`
  from { transform: scale(1); }
  to { transform: scale(1.25); }
`;

const pulseOut = keyframes`
  from { transform: scale(1.25); }
  to { transform: scale(1); }
`;

export { pulseOut, pulseIn, noAnimation };
