import Layer from 'src/engine/Layer';
import * as React from 'react';

import GameObject from '../../engine/objects/GameObject';
import { Subscription } from 'rxjs';
import ObjectService from '../../engine/services/ObjectService';
import GameService, { IObject } from '../../engine/services/GameService';
import { GameObjectType, IGameObject } from '../../interfaces/gameObjects';
import Clickable from '../../style-guide/Clickable';
import TimeService from '../../engine/services/TimeService';
import Flex from '../../style-guide/Flex';
import { getObjectNumber } from '../../utils/objects';

interface IState {
  components: IObject[][];
  newComponents: IObject[][];
}

export default class ObjectContainer extends React.Component<object, IState> {
  public state = {
    components: [
      [] as IObject[],
      [] as IObject[],
      [] as IObject[],
      [] as IObject[],
      [] as IObject[]
    ],
    newComponents: [
      [] as IObject[],
      [] as IObject[],
      [] as IObject[],
      [] as IObject[],
      [] as IObject[]
    ]
  };

  private game = new GameService();
  private time = new TimeService();
  private gameObjects = new ObjectService();

  private animationSub: Subscription | null = null;
  private gameObjectsSub: Subscription | null = null;
  private timeUpdateSub: Subscription | null = null;

  constructor(props: object) {
    super(props);

    this.update = this.update.bind(this);
    this.spawnBox = this.spawnBox.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.resetUpdate = this.resetUpdate.bind(this);
  }

  public componentDidMount() {
    this.animationSub = this.time.update$.subscribe(this.update);
    this.gameObjectsSub = this.gameObjects.new$.subscribe(this.spawnBox);
    this.timeUpdateSub = this.time.timeUpdated$.subscribe(this.resetUpdate);
  }

  public componentWillUnmount() {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
    }
    if (this.gameObjectsSub) {
      this.gameObjectsSub.unsubscribe();
    }
    if (this.timeUpdateSub) {
      this.timeUpdateSub.unsubscribe();
    }
  }

  public render() {
    return (
      <Layer>
        <Flex container justify="space-around">
          {this.state.components.map((row, i) => (
            <Clickable background="transparent" key={i} noBorder>
              {row.map((collidable, j) => (
                <GameObject
                  key={collidable.id}
                  id={collidable.id}
                  type={collidable.type}
                  dilemma={collidable.dilemma}
                  y={0}
                  width={50}
                  fill="red"
                  onDelete={() => this.handleOnDelete(i, j)}
                />
              ))}
            </Clickable>
          ))}
        </Flex>
      </Layer>
    );
  }

  private resetUpdate() {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
    }

    this.animationSub = this.time.update$.subscribe(this.update);
  }

  private async update(renderFrame: number) {
    let { components } = this.state;
    const { newComponents } = this.state;

    components = components.map((row, i) => row.concat(newComponents[i]));

    await this.setState({
      components,
      newComponents: [[], [], [], [], []]
    });
  }

  private spawnBox(objects: IGameObject[]) {
    const { newComponents } = this.state;

    if (objects.length > 0) {
      const object = objects[0];
      this.gameObjects.removeNew(object);

      if (object.type === GameObjectType.ALL) {
        newComponents[0].push(this.game.getNewObject(GameObjectType.ONE, true));
        newComponents[1].push(this.game.getNewObject(GameObjectType.TWO, true));
        newComponents[2].push(
          this.game.getNewObject(GameObjectType.THREE, true)
        );
        newComponents[3].push(
          this.game.getNewObject(GameObjectType.FOUR, true)
        );
        newComponents[4].push(
          this.game.getNewObject(GameObjectType.FIVE, true)
        );
      } else {
        const index = getObjectNumber(object.type);
        newComponents[index].push(this.game.getNewObject(object.type));
      }

      this.setState({ newComponents });
    }
  }

  private async handleOnDelete(i: number, j: number) {
    const { components } = this.state;
    if (!components[i][j]) {
      return;
    }

    if (components[i][j].dilemma) {
      await this.gameObjects.stopDilemma();
      this.setState({ components: [[], [], [], [], []] });
    } else {
      components[i].splice(j, 1);
      this.setState({ components });
    }
  }
}
