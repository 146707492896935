import * as React from 'react';
import GameObject from '../../../engine/objects/GameObject';
import { fromEvent, Subscription } from 'rxjs';
import ObjectService from '../../../engine/services/ObjectService';
import GameService from '../../../engine/services/GameService';
import { GameObjectType, IGameRow } from '../../../interfaces/gameObjects';
import Clickable from '../../../style-guide/Clickable';
import { getUTCTime } from '../../../utils/time';

interface IProps {
  row: IGameRow;
}

export default class Action extends React.Component<IProps> {
  private objects = new ObjectService();
  private game = new GameService();
  private keySub: Subscription | null = null;

  constructor(props: IProps) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.addPoints = this.addPoints.bind(this);
  }

  public componentDidMount() {
    this.keySub = fromEvent(document, 'keydown').subscribe(
      this.handleKeyDown.bind(this)
    );
  }

  public componentWillUnmount() {
    if (this.keySub) {
      this.keySub.unsubscribe();
    }
  }

  public render() {
    const { row } = this.props;
    return (
      <Clickable
        id="clickable"
        background="transparent"
        onTouchStart={() => this.handleOnClick(row.type)}
        onMouseDown={() => this.handleOnClick(row.type)}
      />
    );
  }

  private handleKeyDown(e: KeyboardEvent | Event) {
    const {
      row: { actionKey, type }
    } = this.props;
    if ('key' in e && e.key === actionKey) {
      this.handleOnClick(type);
    }
  }

  private handleOnClick(type: GameObjectType) {
    const spawned = this.objects.getSpawned();
    const collision = spawned.findIndex(this.checkCollision(type));

    if (collision > -1) {
      this.addPoints(spawned[collision].score, type);
      this.objects.removeSpawned(spawned[collision]);
    }
  }

  private checkCollision(type: GameObjectType): (ref: GameObject) => boolean {
    return (ref: GameObject): boolean => {
      return ref.collision(type, getUTCTime());
    };
  }

  private addPoints(points: number, type: GameObjectType) {
    this.game.addPoints(points, type);
  }
}
