import * as React from 'react';
import Action from './actions/Action';
import Flex, { BasedLayer } from '../../style-guide/Flex';
import { IGameRow } from '../../interfaces/gameObjects';
import Layer from '../../engine/Layer';

interface IProps {
  rows: IGameRow[];
}

export default class Actions extends React.Component<IProps> {
  public render() {
    const { rows } = this.props;

    return (
      <Layer zIndex={1}>
        <BasedLayer zIndex={1}>
          <Flex container justify="space-around">
            {rows.map((row, i) => (
              <Action key={i} row={row} />
            ))}
          </Flex>
        </BasedLayer>
      </Layer>
    );
  }
}
