import * as React from 'react';
import { noAnimation, pulseIn, pulseOut } from './pulse';
import { isMobile } from '../../utils/window';

interface IProps {
  showAnimation: boolean;
}

interface IState {
  animation: any;
  animating: any;
}

export default abstract class PulseComponent<T, U> extends React.Component<
  IProps & T,
  IState & U
> {
  public static defaultProps = {
    showAnimation: !isMobile()
  };

  public startAnimation(state: object, animationTime = 100) {
    if (!this.props.showAnimation) {
      return this.setState(state);
    }
    this.setState({ ...state, animating: true, animation: pulseIn }, () => {
      setTimeout(() => {
        this.setState({
          animation: pulseOut
        });

        setTimeout(() => {
          this.setState({
            animating: false,
            animation: noAnimation
          });
        }, animationTime);
      }, animationTime);
    });
  }
}
