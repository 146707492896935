import { styled } from '../utils/styled-components';

const Input = styled.input`
  padding: 0;
  height: 2.5rem;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 1px 3px 10px grey;
  text-align: center;
  font-size: 1.2rem;
`;

export default Input;
