import * as React from 'react';
import { Subscription } from 'rxjs';
import ButtonIcon from '../style-guide/ButtonIcon';
import Svg from '../style-guide/Svg';
import { Sound, SoundOff, SoundOn } from '../style-guide/svg/Icons';
import SoundService from '../engine/services/SoundService';

interface IProps {
  variant?: 'primary' | 'secondary';
}

interface IState {
  soundOn: boolean;
}

export default class SoundToggle extends React.Component<IProps, IState> {
  public static defaultProps = {
    variant: 'primary'
  };

  public state = {
    soundOn: true
  };

  private sound = new SoundService();
  private soundSub: Subscription | null = null;

  constructor(props: IProps) {
    super(props);
    this.setSound = this.setSound.bind(this);
    this.toggleSound = this.toggleSound.bind(this);
  }

  public componentDidMount() {
    this.soundSub = this.sound.sound$.subscribe(this.setSound);
  }

  public componentWillUnmount() {
    if (this.soundSub) {
      this.soundSub.unsubscribe();
    }
  }

  public render() {
    const { variant } = this.props;
    const { soundOn } = this.state;

    switch (variant) {
      case 'primary':
        return (
          <ButtonIcon
            onClick={this.toggleSound}
            color={soundOn ? '#FF5864' : '#BEBEBE'}
          >
            <Sound />
          </ButtonIcon>
        );
      default:
        if (soundOn) {
          return (
            <Svg onClick={this.toggleSound}>
              <SoundOn />
            </Svg>
          );
        } else {
          return (
            <Svg onClick={this.toggleSound}>
              <SoundOff />
            </Svg>
          );
        }
    }
  }

  private setSound(soundOn: boolean) {
    if (this.state.soundOn !== soundOn) {
      this.setState({ soundOn });
    }
  }

  private toggleSound() {
    if (this.state.soundOn) {
      this.sound.off();
    } else {
      this.sound.on();
    }
  }
}
