import * as React from 'react';
import Flex from '../style-guide/Flex';
import CloseIcon from '../style-guide/CloseIcon';
import Info from '../style-guide/Info';

interface IProps extends JSX.ElementChildrenAttribute {
  onClose: () => void;
}

const InfoWindow = ({ onClose, children }: IProps) => (
  <Info>
    <Flex container direction="column" fullHeight>
      <CloseIcon onClick={onClose} />
      {children}
    </Flex>
  </Info>
);

export default InfoWindow;
