const images = ['bg.jpg', 'Dilemma.png', 'dilemmaBG.png'];

export function loadImages() {
  images.map(loadImage);
}

function loadImage(imageSrc: string) {
  const img = new Image();
  img.src = imageSrc;
}

export async function loadAudioFile(src: string) {
  return new Promise<ArrayBufferLike>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', src, true);
    request.responseType = 'arraybuffer';
    request.onerror = reject;
    request.onload = async function() {
      try {
        resolve(request.response);
      } catch (e) {
        reject(e);
      }
    };
    request.send();
  });
}

export async function audioBufferToSource(
  buffer: ArrayBufferLike
): Promise<[AudioBufferSourceNode, AudioContext]> {
  let context = newAudioContext();

  const audioSource = context.createBufferSource();
  audioSource.connect(context.destination);

  audioSource.buffer = await context.decodeAudioData(buffer.slice(0));
  return [audioSource, context];
}

export function newAudioContext() {
  try {
    return new AudioContext();
  } catch (e) {
    throw new Error('Sound context not supported');
  }
}
