import { IAnimation } from './animation/pulse';
import { styled } from '../utils/styled-components';

interface IProps extends IAnimation {
  borderColor: string;
}

const LineIcon = styled.div<IProps>`
  width: ${props => props.theme.settings.actionWidth}px;
  height: ${props => props.theme.settings.actionWidth}px;
  border: 5px solid ${props => props.theme[props.borderColor]};
  border-radius: 50%;
  background-color: ${props => props.theme.white};
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 0.9rem;

  animation: ${props => props.animation} 100ms both;
  will-change: scale;
`;

export default LineIcon;
