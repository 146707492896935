import { BehaviorSubject, Subscription } from 'rxjs';
import GameObject from '../objects/GameObject';
import v4 from 'uuid/v4';
import { defaultSettings, MAX_DILEMMAS } from '../../utils/constants';
import SettingService from './SettingService';
import { GameObjectType, IGameObject } from '../../interfaces/gameObjects';
import { getRandomNumber } from '../../utils/random';
import TimeService from './TimeService';
import SoundService from './SoundService';
import { getObjectFromNumber } from '../../utils/objects';

let instance: ObjectService;

export default class ObjectService {
  public animateDilemma = false;

  private _dilemma$!: BehaviorSubject<boolean>;
  private _dilemmaTime$!: BehaviorSubject<number>;

  private _availableItems$!: BehaviorSubject<number[]>;
  private _newObjects$!: BehaviorSubject<IGameObject[]>;
  private _spawnedObjects$!: BehaviorSubject<GameObject[]>;

  private _settings = new SettingService();
  private _time = new TimeService();
  private _sound = new SoundService();
  private _spawnSub: Subscription | null = null;
  private _dilemmaTimeSub: Subscription | null = null;

  private _defaultItems = [17, 17, 17, 17, 17];
  private _dilemma = false;
  private _dilemmas = 0;
  private _spawned = 0;
  private _totalSpawns = this._defaultItems.reduce(
    (total, item) => total + item,
    0
  );

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
    instance._newObjects$ = new BehaviorSubject<IGameObject[]>([]);
    instance._spawnedObjects$ = new BehaviorSubject<GameObject[]>([]);
    instance._availableItems$ = new BehaviorSubject(this._defaultItems);
    instance._dilemma$ = new BehaviorSubject<boolean>(false);
    instance._dilemmaTime$ = new BehaviorSubject(-1);
    instance._time.timeUpdated$.subscribe(this.resetSpawnSub.bind(instance));
    return instance;
  }

  public startSpawnObjects(gameTime: number, updateTime: number) {
    this._spawned = 0;
    this._dilemmas = 0;
    this.setSpawnObject(gameTime, updateTime);
    this.resetSpawnSub();
  }

  public resetSpawnSub() {
    if (this._spawnSub) {
      this._spawnSub.unsubscribe();
    }
    this._spawnSub = this._time.update$.subscribe(this.update.bind(this));
  }

  public stopSpawnObjects() {
    this._dilemma$.next(false);
    if (this._spawnSub) {
      this._spawnSub.unsubscribe();
      delete this._spawnSub;
    }
  }

  get new$() {
    return this._newObjects$;
  }

  public addNew(object: IGameObject) {
    const array = this._newObjects$.getValue();
    array.push(object);
    this._newObjects$.next(array);
  }

  public removeNew(object: IGameObject) {
    const array = this._newObjects$.getValue();
    const index = array.findIndex(x => x.id === object.id);
    if (index > -1) {
      array.splice(index, 1);
      this._newObjects$.next(array);
    }
  }

  public clear() {
    this._newObjects$.next([]);
    this._spawnedObjects$.next([]);
  }

  get dilemma$() {
    return this._dilemma$;
  }

  get dilemmaTime$() {
    return this._dilemmaTime$;
  }

  get spawned$() {
    return this._spawnedObjects$;
  }

  public getSpawned() {
    return this._spawnedObjects$.getValue();
  }

  public addSpawned(object: GameObject) {
    const array = this._spawnedObjects$.getValue();
    array.push(object);
    this._spawnedObjects$.next(array);
  }

  public removeSpawned(object: GameObject) {
    const array = this._spawnedObjects$.getValue();
    const index = array.findIndex(x => x.props.id === object.props.id);
    if (index > -1) {
      object.delete();
      array.splice(index, 1);
      this._spawnedObjects$.next(array);
    }
  }

  public spawnDilemma() {
    this.addNew({
      id: v4(),
      type: GameObjectType.ALL
    });
    this._dilemma = true;
    this._time.startDilemmaTimer();
    this._dilemmaTime$.next(6);
    this._dilemmaTimeSub = this._time.dilemma$.subscribe(_ => {
      const time = this._dilemmaTime$.getValue() - 1;
      if (!this.animateDilemma && time < 5) {
        this.animateDilemma = true;
        this.startDilemma();
      }
      this._dilemmaTime$.next(time);
    });
  }

  public startDilemma() {
    this._dilemma$.next(true);
    this._settings.set('renderTime', 3000);
    this._sound.setDilemmaMusic();
  }

  public stop() {
    if (this._spawnSub) {
      this._spawnSub.unsubscribe();
    }
  }

  public async stopDilemma() {
    if (this._dilemmaTimeSub) {
      this._dilemmaTimeSub.unsubscribe();
    }
    this._dilemma = false;
    this._time.stopDilemmaTimer();
    this.animateDilemma = false;
    const renderTime = defaultSettings.renderTime - this._dilemmas * 500;
    const updateTime = defaultSettings.updateTime - this._dilemmas * 75;
    this._settings.setSettings({ renderTime, updateTime });
    this._dilemma$.next(false);
    this._dilemmaTime$.next(-1);
    await this._sound.setGameMusic(this._dilemmas + 1);
  }

  private setSpawnObject(gameTime: number, updateTime: number) {
    const spawnPerSec = updateTime / 1175;
    const totalSpawns = gameTime / spawnPerSec;
    const objectSpawns = Math.floor((totalSpawns - 10) / 5) - MAX_DILEMMAS;
    this._availableItems$.next([
      objectSpawns,
      objectSpawns,
      objectSpawns,
      objectSpawns,
      objectSpawns
    ]);
    this._totalSpawns = objectSpawns * 5 + MAX_DILEMMAS;
  }

  private update(counter: number, random: number = getRandomNumber(0, 5)) {
    if (this._dilemma || this._dilemmaTime$.getValue() > 0) {
      return;
    }

    const available = this._availableItems$.getValue();

    if (this.shouldSpawnDilemma()) {
      ++this._spawned;
      this.spawnDilemma();
      return;
    } else {
      if (random > 4) {
        const high = available.reduce(
          (obj, x, i) => (x > obj.x ? { i, x } : obj),
          { i: 0, x: 0 }
        );
        random = high.i;
        if (available[random] === 0) {
          return;
        }
      }

      if (available[random] <= 0) {
        this.update(counter, random + 1);
        return;
      }
    }

    --available[random];
    this._availableItems$.next(available);
    ++this._spawned;

    this.addNew({
      id: v4(),
      type: getObjectFromNumber(random)
    });
  }

  private shouldSpawnDilemma() {
    if (this._dilemmas === 0 && this._spawned > this._totalSpawns * 0.25) {
      ++this._dilemmas;
      return true;
    }

    if (this._dilemmas === 1 && this._spawned > this._totalSpawns * 0.6) {
      ++this._dilemmas;
      return true;
    }

    return false;
  }
}
