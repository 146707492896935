import * as React from 'react';
import { getAllHighscores } from '../fire';
import PlayAgain from '../components/PlayAgain';
import GameService, { Pages } from '../engine/services/GameService';
import { getObjectColor, getObjectType } from '../utils/objects';
import Flex from '../style-guide/Flex';
import { H2 } from '../style-guide/Typography';
import CloseIcon from '../style-guide/CloseIcon';
import { Medal } from '../style-guide/svg/Icons';
import Info from '../style-guide/Info';
import { styled } from '../utils/styled-components';

const ListContainer = styled(Flex).attrs({
  flex: '0 1 auto',
  item: true,
  xs: 10
})`
  height: 65%;
  margin-top: 100px;
  z-index: 1;
`;

const List = styled(Flex)`
  overflow-y: scroll !important;
  margin-top: 5.5rem;
  z-index: 2;
`;

const HighscoreBackground = styled.div`
  align-self: center;
  position: absolute;
  height: 200px;
  width: 200px;
  margin-top: -50px;
  z-index: -2;
  border-radius: 50%;
  background-color: ${props => props.theme.white};
`;

const HighscoreIcon = styled(Flex).attrs({
  align: 'center',
  alignself: 'center',
  container: true,
  direction: 'column',
  item: true,
  justify: 'space-between'
})`
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin-top: -50px;
  padding: 0.5rem 0 4rem 0;
  z-index: 0;
`;

const StyledH2 = styled(H2)`
  text-transform: capitalize;
`;

const Table = styled.table`
  width: 100%;
  text-align: left;
  padding: 0 2rem;
  z-index: 3;

  @media (max-width: 500px) {
    padding: 0 1rem;
  }
`;

const Tr = styled.tr`
  margin-bottom: 0.8rem;
  box-shadow: 2px 2px 3px 2px #e4e4e4;
  display: table;
  width: 100%;
  border-radius: 4px;
  z-index: 4;
`;

const Td = styled.td<{ align?: 'left' | 'right'; color?: string }>`
  color: ${props => (props.color ? props.color : props.theme.blue)};
  font-size: 1.5rem;
  font-weight: normal;
  text-align: ${props => props.align || 'left'};
  padding: 0.35rem 0.35rem;
  margin-top: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${props => props.theme.settings.width * 0.35}px;
  z-index: 5;
`;

export default class Highscore extends React.Component {
  public state = {
    points: []
  };
  private game = new GameService();

  constructor(props: object) {
    super(props);
    this.startGame = this.startGame.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  public async componentDidMount() {
    await this.getAllHighscores();
  }

  public getAllHighscores = async () => {
    const points = await getAllHighscores();
    this.setState({ points });
  };

  public renderHighscores() {
    const { points } = this.state;
    const highscoreListMax = 25;

    if (points.length < 1) {
      return null;
    }

    return points.map((item: any, i) => {
      if (i < highscoreListMax) {
        return (
          <Tr key={i}>
            <Td>
              {i + 1}. {item.name}
            </Td>
            <Td
              color={getObjectColor(getObjectType(item.topCourse))}
              align="right"
            >
              {item.score}
            </Td>
          </Tr>
        );
      } else {
        return null;
      }
    });
  }

  public render() {
    return (
      <Flex container justify="center" align="center" fullHeight wrap="wrap">
        <ListContainer>
          <Info shadow="6px 8px 10px #888, 0px 5px 3px #888">
            <Flex container direction="column" fullHeight>
              <CloseIcon onClick={this.goBack} />
              <HighscoreBackground />
              <HighscoreIcon>
                <Flex item>
                  <Medal />
                </Flex>
                <Flex item>
                  <StyledH2>Toppliste</StyledH2>
                </Flex>
              </HighscoreIcon>
              <List item>
                <Table>
                  <tbody>{this.renderHighscores()}</tbody>
                </Table>
              </List>
            </Flex>
          </Info>
        </ListContainer>
        <PlayAgain />
      </Flex>
    );
  }

  private async startGame() {
    await this.game.startGame();
  }

  private goBack() {
    this.game.route(Pages.END);
  }
}
