import * as React from 'react';
import { Subscription } from 'rxjs';
import ObjectService from '../services/ObjectService';
import SettingService from '../services/SettingService';
import { GameObjectType } from '../../interfaces/gameObjects';
import GameService from '../services/GameService';
import TimeService from '../services/TimeService';
import { getObjectIcon } from '../../utils/objects';
import { getUTCTime } from '../../utils/time';
import Close from '../../style-guide/Close';

interface ICollidable {
  update: () => void;
  render: () => React.ReactNode;
  isOutOfBounds: () => boolean;
  collision: (cType: GameObjectType, time: number) => boolean;
}

interface IProps {
  y: number;
  dilemma: boolean;
  type: GameObjectType;
  width: number;
  fill: string;
  id: string;
  onDelete: () => void;
}

interface IState {
  animating: boolean;
  collided: boolean;
  deleted: boolean;
  score: number;
  timeAnimationEnd: number;
  timeAnimationStart: number;
  timeUpdated: number;
  y: number;
}

export default class GameObject extends React.Component<IProps, IState>
  implements ICollidable {
  public state = {
    Icon: getObjectIcon(this.props.type),
    animating: false,
    collided: false,
    deleted: false,
    score: 0,
    timeAnimationEnd: 0,
    timeAnimationStart: 0,
    timeUpdated: 0,
    y: this.props.y || 0
  };

  public score: number = 0;
  private readonly SPEED: number = 0;

  private timeUpdated = 0;
  private game = new GameService();
  private gameObjects: ObjectService;
  private settings: SettingService;
  private animation: Subscription | null = null;

  constructor(props: IProps) {
    super(props);
    this.settings = new SettingService();
    this.SPEED = this.settings.get('height');

    this.gameObjects = new ObjectService();
    this.gameObjects.addSpawned(this);
  }

  public componentDidMount() {
    this.animation = new TimeService().update$.subscribe(
      this.update.bind(this)
    );
  }

  public componentWillUnmount() {
    if (this.animation) {
      this.animation.unsubscribe();
    }
  }

  public shouldComponentUpdate(newProps: IProps, newState: IState) {
    return !this.state.animating;
  }

  public update() {
    const { dilemma } = this.props;
    const { animating, deleted, y } = this.state;

    if (deleted) {
      return;
    }

    if (this.isOutOfBounds()) {
      this.game.resetCombo();
    }

    const newState: any = {};

    if (!animating) {
      if (dilemma && !this.gameObjects.animateDilemma) {
        return;
      }
      newState.timeAnimationStart = getUTCTime();
      newState.timeAnimationEnd = getUTCTime(this.settings.get('renderTime'));
      newState.animating = true;
      newState.y = y + this.SPEED;
      this.setState(newState);
    } else {
      this.timeUpdated = getUTCTime();
    }
  }

  public render() {
    const { fill } = this.props;
    const { y, Icon } = this.state;

    return (
      <Close y={y} fill={fill}>
        <Icon />
      </Close>
    );
  }

  public delete() {
    this.setState({
      deleted: true
    });
    this.props.onDelete();
  }

  public isOutOfBounds() {
    const { y, timeAnimationEnd } = this.state;
    if (
      this.settings.get('height') <= y &&
      this.timeUpdated >= timeAnimationEnd
    ) {
      this.delete();
      return true;
    }
    return false;
  }

  public collision(cType: GameObjectType, time: number) {
    const { type } = this.props;
    const { collided, y } = this.state;

    if (cType !== type || collided || y <= 0) {
      return false;
    }

    if (this.checkCollision(time)) {
      this.setState({
        collided: true
      });
      return true;
    }

    return false;
  }

  private checkCollision(time: number): boolean {
    const { timeAnimationEnd, timeAnimationStart } = this.state;
    const pxPerMS =
      (timeAnimationEnd - timeAnimationStart) / this.settings.get('height');
    const from = pxPerMS * this.settings.get('bottomMargin');
    const to = pxPerMS * this.settings.get('actionWidth') * 2 + from;
    const difference = timeAnimationEnd - time;

    if (difference < 0 || difference < from || difference > to) {
      return false;
    }

    this.score = this.calculateScore(from, to, difference);
    return true;
  }

  private calculateScore(from: number, to: number, difference: number) {
    const MAX_SCORE = 100;

    const ceilFrom = from - from;
    const ceilTo = to - from;
    const ceilDiff = difference - from;

    const center = (ceilTo + ceilFrom) / 2;
    const fromCenter = Math.abs(Math.abs(ceilDiff - center) - center);
    const SCORE = Math.abs((MAX_SCORE * fromCenter) / (ceilTo - ceilFrom));

    return Math.ceil(SCORE) * 2;
  }
}
