import * as React from 'react';

import { of, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import GameService, { IAccuracy } from '../engine/services/GameService';
import { IAnimation, noAnimation } from '../style-guide/animation/pulse';
import { H1 } from '../style-guide/Typography';
import PulseComponent from '../style-guide/animation/PulseComponent';
import { styled } from '../utils/styled-components';

interface IState {
  accuracy: IAccuracy;
  show: boolean;
}

export default class Accurate extends PulseComponent<object, IState> {
  public state = {
    accuracy: { text: '', color: '' },
    animating: false,
    animation: noAnimation,
    show: false
  };

  private game = new GameService();
  private accuracySub: Subscription | null = null;
  private animationSub: Subscription | null = null;

  public componentDidMount() {
    this.accuracySub = this.game.accuracy$.subscribe(
      this.setAccuracy.bind(this)
    );
  }

  public componentWillUnmount() {
    if (this.accuracySub) {
      this.accuracySub.unsubscribe();
    }
  }

  public render() {
    const { animation, animating, accuracy, show } = this.state;

    if (!show) {
      return null;
    }

    return (
      <StyledText
        animation={animation}
        animating={animating}
        color={accuracy.color}
      >
        {accuracy.text}
      </StyledText>
    );
  }

  private async setAccuracy(accuracy: IAccuracy) {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
    }

    this.animationSub = of(true)
      .pipe(
        tap(() => {
          this.startAnimation({ accuracy, show: true });
        }),
        delay(550),
        tap(() => {
          this.setState({ show: false });
        })
      )
      .subscribe();
  }
}

const StyledText = styled(H1)<IAnimation & { color: string }>`
  animation: ${props => props.animation} 100ms both;
  will-change: scale;
  z-index: 1;
  color: ${props => props.color};
`;
