import * as React from 'react';
import Flex from './Flex';
import { styled } from '../utils/styled-components';

const IconWrapper = styled(Flex)`
  cursor: pointer;
`;

const TitleWrapper = styled(Flex)`
  color: ${props => props.theme.blue};
  font-weight: 600;
`;

interface IProps extends JSX.ElementChildrenAttribute {
  title: string;
  onClick: () => void;
}

const ButtonLargeIcon = ({ children, onClick, title }: IProps) => (
  <Flex container direction="column" align="center">
    <IconWrapper item onClick={onClick}>
      {children}
    </IconWrapper>
    <TitleWrapper item>{title}</TitleWrapper>
  </Flex>
);

export default ButtonLargeIcon;
