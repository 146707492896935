import * as React from 'react';
import CourseGraph from './end/CourseGraph';
import Placement from './end/Placement';
import PlayAgain from '../components/PlayAgain';
import ButtonLargeIcon from '../style-guide/ButtonLargeIcon';
import {
  ArrowCircle,
  Facebook,
  MedalCircle,
  TrophyCircle
} from '../style-guide/svg/Icons';
import {
  getObjectColor,
  getObjectText,
  getObjectType,
  getObjectUrl
} from '../utils/objects';
import { H1, P } from '../style-guide/Typography';
import Info from '../style-guide/Info';
import Layer from '../engine/Layer';
import GameService, {
  ICategoryChosen,
  Pages
} from '../engine/services/GameService';
import Flex from '../style-guide/Flex';
import { FACEBOOK_SHARE, URL_STEINER_HOMEPAGE } from '../utils/constants';
import { styled } from '../utils/styled-components';

export default class End extends React.Component {
  private game = new GameService();

  constructor(props: object) {
    super(props);
    this.goHome = this.goHome.bind(this);
    this.showHighscore = this.showHighscore.bind(this);
    this.shareOnFacebook = this.shareOnFacebook.bind(this);
    this.showAllSchoolCategories = this.showAllSchoolCategories.bind(this);
  }

  public render() {
    const categories = this.game.categories$.getValue();
    const category = this.getPreferredLineColor(categories);
    const color = getObjectColor(getObjectType(category.key));

    return (
      <Layer>
        <Flex
          container
          justify="center"
          align="center"
          fullHeight
          direction="column"
        >
          <FlexWithHat item xs={10} flex="0 1 auto">
            <Info>
              <StyledContent
                container
                direction="column"
                justify="space-between"
                fullHeight
                gap={16}
                gapDivider={2}
              >
                <StyledIcon item alignself="center">
                  <TrophyCircle />
                </StyledIcon>
                <Flex item>
                  <Placement />
                  <StyledScore color={color}>
                    {this.game.getPoints()}
                  </StyledScore>
                </Flex>
                <GraphContainer container item align="flex-end">
                  <CourseGraph categories={categories} />
                </GraphContainer>
                <StyledText item>
                  <P variant="secondary">
                    {getObjectText(category.key)}
                    <br />
                    <strong>
                      Les mer om vår {category.key}
                      linje{' '}
                      <a
                        href={getObjectUrl(category.key)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Strong color={getObjectColor(category.key)}>
                          her!
                        </Strong>
                      </a>
                    </strong>
                  </P>
                </StyledText>
                <Flex container item justify="space-between">
                  <ButtonLargeIcon
                    title="Toppliste"
                    onClick={this.showHighscore}
                  >
                    <MedalCircle />
                  </ButtonLargeIcon>
                  <ButtonLargeIcon title="Del" onClick={this.shareOnFacebook}>
                    <Facebook />
                  </ButtonLargeIcon>
                  <ButtonLargeIcon
                    title="Se alle linjer"
                    onClick={this.showAllSchoolCategories}
                  >
                    <ArrowCircle />
                  </ButtonLargeIcon>
                </Flex>
              </StyledContent>
            </Info>
          </FlexWithHat>
          <PlayAgain hideLink flex=".5 1 auto" />
        </Flex>
      </Layer>
    );
  }

  private getPreferredLineColor(categories: ICategoryChosen) {
    let category = {
      key: 'Media',
      total: 0
    };

    for (const key in categories) {
      if (!categories.hasOwnProperty(key)) {
        continue;
      }
      if (categories[key] > category.total) {
        category = { total: categories[key], key };
      }
    }

    return category;
  }

  private goHome() {
    this.game.route(Pages.HOME);
  }

  private showHighscore() {
    this.game.route(Pages.HIGHSCORE);
  }

  private shareOnFacebook() {
    const points = this.game.getPoints();
    const placement = this.game.placement$.getValue();

    const url = `https://www.facebook.com/sharer/sharer.php`;
    const quote = FACEBOOK_SHARE(points, placement);
    const facebookWindow = window.open(
      `${url}?u=${document.URL}&quote=${quote}`,
      'facebook-popup',
      'height=350,width=600'
    );

    if (facebookWindow && facebookWindow.focus) {
      facebookWindow.focus();
    }
  }

  private showAllSchoolCategories() {
    window.open(URL_STEINER_HOMEPAGE, '_blank');
  }
}

const FlexWithHat = styled(Flex)`
  max-height: 600px;
  margin-top: 4.5rem;
  padding-bottom: 1rem;
  width: 80%;

  @media (max-width: 400px) {
    width: auto;
  }

  @media (max-height: 750px) {
    margin-top: 2.5rem;
  }
`;

const StyledScore = styled(H1)`
  font-size: 3.5rem;
  line-height: 4rem;
`;

const StyledIcon = styled(Flex)`
  margin-top: -4.5rem;
  margin-bottom: -1rem;

  @media (max-height: 750px) {
    margin-top: -2.5rem;
  }
`;

const StyledContent = styled(Flex)`
  margin-top: 3rem;
  padding: 0 2.5rem;

  @media (max-height: 750px) {
    padding: 0 1rem;
  }
`;

const StyledText = styled(Flex)`
  text-align: left;
  margin: 0 1rem;
`;

const GraphContainer = styled(Flex)`
  width: 100%;
  height: auto;
  min-height: 175px;

  @media (max-height: 750px) {
    min-height: auto;
  }
`;

const Strong = styled.strong<{ color: string }>`
  font-weight: bold;
  text-decoration: underline;
  color: ${props => props.color};
`;
