import { styled } from '../utils/styled-components';

interface IProps {
  color?: string;
  padding?: string;
}

const ButtonIcon = styled.button<IProps>`
  background-color: ${props => (props.color ? props.color : props.theme.white)};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  ${props => props.padding && `padding: ${props.padding}`};
`;

export default ButtonIcon;
