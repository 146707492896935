import { BehaviorSubject } from 'rxjs';
import { defaultSettings } from '../../utils/constants';

export interface ISettings {
  actionWidth: number;
  bottomMargin: number;
  gameTime: number;
  height: number;
  points: number;
  renderTime: number;
  updateTime: number;
  width: number;
}
let instance: SettingService;

export default class SettingService {
  private settings$!: BehaviorSubject<ISettings>;

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
    instance.settings$ = new BehaviorSubject<ISettings>(defaultSettings);
    return instance;
  }

  public get(value: keyof ISettings) {
    return this.settings$.getValue()[value];
  }

  public set<K extends keyof ISettings>(
    key: keyof ISettings,
    value: ISettings[K]
  ) {
    const settings = this.settings$.getValue();
    settings[key] = value;
    this.settings$.next(settings);
  }

  public getSettings() {
    return this.settings$.getValue();
  }

  public setSettings(newSettings: Partial<ISettings>) {
    const value = this.settings$.getValue();
    this.settings$.next({
      ...value,
      ...newSettings
    });
  }

  get $() {
    return this.settings$;
  }

  public startGame() {
    this.setSettings({
      gameTime: defaultSettings.gameTime,
      points: defaultSettings.points,
      renderTime: defaultSettings.renderTime,
      updateTime: defaultSettings.updateTime
    });
  }

  public endGame(points: number) {
    this.setSettings({
      points
    });
  }
}
