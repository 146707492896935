import * as React from 'react';
import { styled } from '../utils/styled-components';

const SvgWrapper = styled.div`
  width: 60px;
  cursor: pointer;
`;

const Svg = ({
  children,
  onClick
}: { onClick: () => void } & JSX.ElementChildrenAttribute) => (
  <SvgWrapper onClick={onClick}>{children}</SvgWrapper>
);

export default Svg;
