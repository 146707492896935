import * as React from 'react';
import { Subscription } from 'rxjs';
import { Trophy } from '../../../style-guide/svg/Icons';
import GameService from '../../../engine/services/GameService';
import { IAnimation, noAnimation } from '../../../style-guide/animation/pulse';
import Flex from '../../../style-guide/Flex';
import PulseComponent from '../../../style-guide/animation/PulseComponent';
import { styled } from '../../../utils/styled-components';

const StyledScore = styled(Flex).attrs({
  container: true,
  gap: 8
})<IAnimation>`
  padding-left: 2rem;
  font-size: 1.7rem;
  color: ${props => props.theme.white};
  animation: ${props => props.animation} 100ms both;
  will-change: scale;
`;

interface IState {
  animation: string;
  points: number;
}

export default class Score extends PulseComponent<object, IState> {
  public state = {
    animating: false,
    animation: noAnimation,
    points: 0
  };

  private game = new GameService();
  private scoreSub: Subscription | null = null;

  public componentDidMount() {
    this.scoreSub = this.game.points$.subscribe(this.updatePoints.bind(this));
  }

  public componentWillUnmount() {
    if (this.scoreSub) {
      this.scoreSub.unsubscribe();
    }
  }

  public render() {
    const { animation, points, animating } = this.state;
    return (
      <StyledScore animation={animation} animating={animating}>
        <Trophy />
        {points}
      </StyledScore>
    );
  }

  private updatePoints(points: number) {
    if (this.state.points === points) {
      return;
    }

    this.startAnimation({ points });
  }
}

export { StyledScore };
