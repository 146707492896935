import * as React from 'react';
import Stage from './engine/Stage';
import { fromEvent, Subscription } from 'rxjs';
import Router from './views/Router';
import Background from './components/Background';
import PreloadSvg from './components/PreloadSvg';
import GameService, { Pages } from './engine/services/GameService';
import SettingService, { ISettings } from './engine/services/SettingService';
import { loadImages } from './utils/files';
import DesktopBackground from './style-guide/DesktopBackground';
import Flex from './style-guide/Flex';
import SoundService from './engine/services/SoundService';
import theme from './utils/theme';
import { getScreenHeight, getScreenWidth } from './utils/window';
import { ThemeProvider } from './utils/styled-components';

interface IState {
  settings: ISettings | null;
}

class App extends React.Component<object, IState> {
  public sound = new SoundService();
  public settings = new SettingService();
  public game = new GameService();
  public state = {
    settings: this.settings.getSettings()
  };

  public subscription: Subscription | null = null;
  private sizeSub: Subscription | null = null;
  private loadSub: Subscription | null = null;
  private tochmoveSub: Subscription | null = null;
  private contextEvent: Subscription | null = null;

  public async componentDidMount() {
    // @ts-ignore: IS added from other script
    if (typeof window.addToHomescreen === 'function') {
      // @ts-ignore: IS added from other script
      window.addToHomescreen();
    }
    this.sound.loadAudioFiles();
    loadImages();

    if (navigator.userAgent.match(/Android/i)) {
      this.contextEvent = fromEvent(document, 'contextmenu').subscribe(e => {
        e.preventDefault();
      });
    }

    this.sizeSub = fromEvent(window, 'resize').subscribe(
      this.handleScreenChange.bind(this)
    );
    this.loadSub = fromEvent(window, 'load').subscribe(() => {
      window.scrollTo(0, 0);
    });
    this.tochmoveSub = fromEvent(document, 'touchmove', {
      passive: false
    }).subscribe(e => {
      if (this.game.page.getValue() === Pages.HIGHSCORE) {
        return;
      }
      // @ts-ignore
      if (e.target && e.target.id === 'clickable') {
        const clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent('mousedown', true);
        e.target.dispatchEvent(clickEvent);
      }
      e.preventDefault();
    });
    this.subscription = new SettingService().$.subscribe(
      this.updateSettings.bind(this)
    );
  }

  public componentWillUnmount() {
    if (this.contextEvent) {
      this.contextEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.sizeSub) {
      this.sizeSub.unsubscribe();
    }
    if (this.loadSub) {
      this.loadSub.unsubscribe();
    }
    if (this.tochmoveSub) {
      this.tochmoveSub.unsubscribe();
    }
    this.sound.onDestroy();
  }

  public render() {
    const { settings } = this.state;
    return (
      <>
        <PreloadSvg />
        <ThemeProvider theme={{ ...theme, settings }}>
          <Flex container justify="center">
            <Stage>
              <Background />
              <Router />
            </Stage>
          </Flex>
        </ThemeProvider>
        <DesktopBackground key={1} />
      </>
    );
  }

  private updateSettings(settings: ISettings) {
    this.setState({ settings });
  }

  private handleScreenChange() {
    this.settings.setSettings({
      height: getScreenHeight(),
      width: getScreenWidth()
    });
  }
}

export default App;
