import { css, styled } from '../utils/styled-components';

interface IHeader {
  color?: string;
}

const Header = (color: string) => css<IHeader>`
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;
  margin: 0;
  color: ${props => (props.color ? props.color : color)};
`;

const H1 = styled.h1`
  ${props => Header(props.theme.blue)};
`;

const H2 = styled.h2`
  ${props => Header(props.theme.red)};
`;

const H3 = styled.h3`
  ${Header('#35697E')};
  font-size: 1.8rem;
  font-weight: normal;
  cursor: pointer;
`;

const H4 = styled.h4`
  ${Header('#35697E')};
  text-transform: initial;
`;

const P = styled.p<{ variant?: 'primary' | 'secondary' }>`
  font-family: 'Titillium Web', sans-serif;
  color: ${props =>
    props.variant !== 'primary' ? props.theme.grey : props.theme.blue};
  line-height: 1.5rem;
  margin: 0;
`;

const Error = styled.p`
  color: ${props => props.theme.red};
  text-align: center;
  font-size: 1.1rem;
  margin: 0.5rem 0 0 0;
`;

const Strong = styled.strong`
  font-size: 0.85rem;
`;

export { H1, H2, H3, H4, P, Error, Strong };
