import * as React from 'react';
import { styled } from '../utils/styled-components';

const EngineState = styled.div`
  width: ${props => props.theme.settings.width}px;
  height: ${props => props.theme.settings.height}px;

  @media (min-width: 500px) {
    box-shadow: 0 0 10px 0 ${props => props.theme.red};
  }
`;

export default function(props: JSX.ElementChildrenAttribute) {
  return <EngineState>{props.children}</EngineState>;
}
