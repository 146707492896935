export enum GameObjectType {
  ONE = 'Media',
  TWO = 'Kunst',
  THREE = 'Musikk',
  FOUR = 'Real',
  FIVE = 'Studiespes',
  ALL = 'ALLE'
}

export interface IGameObject {
  id: string;
  type: GameObjectType;
}

export interface IGameRow {
  color: string;
  type: GameObjectType;
  actionKey: string;
}
