import { BehaviorSubject } from 'rxjs';
import v4 from 'uuid/v4';
import { createUser, getAllHighscores } from '../../fire';
import ObjectService from './ObjectService';
import SettingService from './SettingService';
import { GameObjectType } from '../../interfaces/gameObjects';
import { backgroundGradients, defaultSettings } from '../../utils/constants';
import TimeService from './TimeService';
import SoundService from './SoundService';
import theme from '../../utils/theme';
import { getRandomInt } from '../../utils/math';

export enum Pages {
  HOME = 'HOME',
  PLAY = 'PLAY',
  END = 'END',
  HIGHSCORE = 'HIGHSCORE'
}

export interface IObject {
  id: string;
  type: GameObjectType;
  dilemma: boolean;
}

export interface IAccuracy {
  text: string;
  color: string;
}

export type ICategoryChosen = { [key in GameObjectType]: number };

export const defaultCategories = {
  [GameObjectType.ONE]: 0,
  [GameObjectType.TWO]: 0,
  [GameObjectType.THREE]: 0,
  [GameObjectType.FOUR]: 0,
  [GameObjectType.FIVE]: 0,
  [GameObjectType.ALL]: 0
};

let instance: GameService;

export default class GameService {
  public nick!: BehaviorSubject<string>;
  public points$!: BehaviorSubject<number>;
  public categories$!: BehaviorSubject<ICategoryChosen>;
  public page!: BehaviorSubject<Pages>;
  public background$!: BehaviorSubject<string>;
  public combo$!: BehaviorSubject<number>;
  public accuracy$!: BehaviorSubject<IAccuracy>;
  public placement$!: BehaviorSubject<number>;

  private settings!: SettingService;
  private time!: TimeService;
  private objects!: ObjectService;
  private sound!: SoundService;

  constructor() {
    if (instance) {
      return instance;
    }

    this.settings = new SettingService();
    this.time = new TimeService();
    this.objects = new ObjectService();
    this.sound = new SoundService();

    this.nick = new BehaviorSubject('');
    this.points$ = new BehaviorSubject(0);
    this.page = new BehaviorSubject<Pages>(Pages.HOME);
    this.categories$ = new BehaviorSubject({ ...defaultCategories });
    this.background$ = new BehaviorSubject<string>(backgroundGradients[0]);
    this.combo$ = new BehaviorSubject<number>(0);
    this.accuracy$ = new BehaviorSubject<IAccuracy>({
      color: '',
      text: ''
    });
    this.placement$ = new BehaviorSubject<number>(-1);

    instance = this;
    return instance;
  }

  public route(page: Pages) {
    this.page.next(page);
  }

  get timer$() {
    return this.time.timer$;
  }

  public async startGame(playerName?: string) {
    this.objects.clear();
    this.settings.set('renderTime', defaultSettings.renderTime);
    this.accuracy$.next(this.getAccuracy(0));
    this.combo$.next(0);
    this.nick.next(playerName || this.nick.getValue());
    this.background$.next(
      backgroundGradients[getRandomInt(0, backgroundGradients.length - 1)]
    );
    this.points$.next(0);
    this.categories$.next({ ...defaultCategories });
    this.settings.startGame();
    this.time.startGameTimer();
    this.objects.startSpawnObjects(
      this.settings.get('gameTime'),
      this.settings.get('updateTime')
    );
    this.page.next(Pages.PLAY);
    await this.sound.setGameMusic(1, true);
    await this.sound.gameStart();
  }

  public async saveHighscore() {
    const user = v4();
    const path = 'users/' + user;

    const categories = this.categories$.getValue();

    const categoryValues = Object.keys(categories).reduce(
      (obj, key) => {
        const num = categories[key];
        return categories[key] > obj.num ? { key, num } : obj;
      },
      { key: 'None', num: 0 }
    );

    const data = {
      name: this.nick.value,
      score: this.points$.value,
      topCourse: categoryValues.key
    };

    await createUser(path, data);

    const highscores = await getAllHighscores();

    const index = highscores.findIndex(x => x.user === user);

    this.placement$.next(index);
  }

  public async endGame(restart: boolean = false) {
    this.settings.endGame(this.getPoints());
    this.time.stopGameTimer();
    this.objects.stopSpawnObjects();
    await this.objects.stopDilemma();
    this.objects.clear();
    this.objects.stop();
    this.background$.next(backgroundGradients[0]);

    if (restart) {
      this.page.next(Pages.HOME);
    } else {
      this.page.next(Pages.END);
      await this.saveHighscore();
      await this.sound.gameOver();
    }
  }

  public getPoints() {
    return this.points$.getValue();
  }

  public addPoints(points: number, type: GameObjectType) {
    this.points$.next(
      this.points$.getValue() + points + this.combo$.getValue()
    );
    this.accuracy$.next(this.getAccuracy(points));
    this.addCombo();
    this.addToCategory(points, type);
  }

  public addCombo() {
    this.combo$.next(this.combo$.getValue() + 1);
  }

  public resetCombo() {
    this.combo$.next(0);
  }

  public addToCategory(points: number, category: GameObjectType) {
    const categories = this.categories$.getValue();
    categories[category] = (categories[category] || 0) + points;
    this.categories$.next(categories);
  }

  public getCategories() {
    const categories = this.categories$.getValue();
    return Object.keys(categories).map(x => ({
      key: x,
      value: categories[x]
    }));
  }

  public getNewObject(type: GameObjectType, dilemma = false): IObject {
    return {
      dilemma,
      id: v4(),
      type
    };
  }

  public getAccuracy(percentage: number): IAccuracy {
    if (percentage >= 80) {
      this.sound.onClick();
      return { text: `${percentage}%`, color: theme.red };
    }

    if (percentage >= 40) {
      return { text: `${percentage}%`, color: theme.blue };
    }

    return { text: '', color: '' };
  }
}
