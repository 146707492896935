import { styled } from '../utils/styled-components';

const Layer = styled.div<IProps>`
  position: absolute;
  width: inherit;
  height: ${props => (props.height ? props.height : 'inherit')};
  z-index: ${props => (props.zIndex ? props.zIndex : 0)};
`;

interface IProps {
  zIndex?: number;
  height?: string;
}

export default Layer;
