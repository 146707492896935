import * as React from 'react';
import { Subscription } from 'rxjs';
import GameBoard from './GameBoard';
import Home from './Home';
import End from './End';
import Highscore from './Highscore';
import GameService, { Pages } from '../engine/services/GameService';

interface IState {
  page: Pages;
}

export default class Router extends React.Component<object, IState> {
  public state = {
    page: Pages.HOME
  };

  private game = new GameService();
  private pageSub: Subscription | null = null;

  public componentDidMount() {
    this.pageSub = this.game.page.subscribe(this.updatePage.bind(this));
  }

  public componentWillUnmount() {
    if (this.pageSub) {
      this.pageSub.unsubscribe();
    }
  }

  public render() {
    const { page } = this.state;

    switch (page) {
      case Pages.HOME:
        return <Home />;
      case Pages.PLAY:
        return <GameBoard />;
      case Pages.END:
        return <End />;
      case Pages.HIGHSCORE:
        return <Highscore />;
      default:
        return 'No Page';
    }
  }

  private updatePage(page: Pages) {
    if (this.state.page !== page) {
      this.setState({ page });
    }
  }
}
