import * as React from 'react';
import ReactSVG from 'react-svg';
import './svg.css';
import { getRandomNumber } from '../../utils/random';

const Icons = (icon: string, className: string = 'fullWidth') => {
  return () => <ReactSVG src={`real/${icon}.svg`} className={className} />;
};

const Book = Icons('book');
const Pen = Icons('pen');

const Real1 = Icons('atom');
const Real2 = Icons('calculator');
const Real3 = Icons('scope');

export default (num = getRandomNumber(0, 4)) => {
  switch (num) {
    case 0:
      return Real1;
    case 1:
      return Real2;
    case 2:
      return Real3;
    case 3:
      return Book;
    default:
      return Pen;
  }
};
